import * as _ from 'lodash';
import { createSelector } from 'reselect';

import { util } from '../../services';
import { WorkspaceState } from './reducer';

const getWorkspaceState = (state: any) => state.workspace as WorkspaceState;

const isLoading = createSelector(getWorkspaceState, ({ loading }) => loading);

const currentId = createSelector(getWorkspaceState, ({ currentWorkspaceId }) => currentWorkspaceId);

const WorkspaceById = (currentId: string) => {
  return createSelector(getWorkspaceState, (state: WorkspaceState) => {
    return state.workspacesMap[currentId] || {};
  });
};

const currentConvertedHTML = createSelector(
  getWorkspaceState,
  currentId,
  (state: WorkspaceState, currentId: string) =>
    (state.workspacesMap[currentId] && state.workspacesMap[currentId].convertedHTML) || ''
);

const urlPath: any = (currentId: string) => {
  return createSelector(getWorkspaceState, (state: WorkspaceState) => {
    return (state.workspacesMap[currentId] && state.workspacesMap[currentId].urlPath) || '';
  });
};

const currentLegalTextCount = createSelector(
  getWorkspaceState,
  currentId,
  (state: WorkspaceState, currentId: string) =>
    (state.workspacesMap[currentId] && state.workspacesMap[currentId].legalTextCount) || 0
);

const legalTextById = (startIndex: string) => {
  // @ts-ignore
  return createSelector(currentConvertedHTML, (htmlString: string) => {
    const tempEle: any = document.createElement('div');
    tempEle.innerHTML = htmlString;
    return tempEle.querySelector('#' + startIndex).innerHTML;
  });
};

const nameById: any = (currentId: any) => {
  return createSelector(getWorkspaceState, (state: WorkspaceState) => {
    if (_.isNil(currentId)) return null;
    else return state.workspacesMap[currentId] && state.workspacesMap[currentId].name;
  });
};

const legalTextsInBetween = (startLegalTextId: string, endLegalTextId: string) => {
  // @ts-ignore
  return createSelector(currentConvertedHTML, (htmlString: string) => {
    let legalTexts = '\n';
    const tempEle: any = document.createElement('div');
    tempEle.innerHTML = htmlString;
    let startId = util.toNum(startLegalTextId);
    const endId = util.toNum(endLegalTextId);
    while (endId >= startId) {
      const legalText = tempEle.querySelector('#' + 'lt-' + startId).innerHTML;
      legalTexts = legalTexts.concat(legalText).concat('\n').concat('\n');
      startId++;
    }
    return legalTexts;
  });
};

const workspaceReadMode = createSelector(getWorkspaceState, ({ workspaceReadMode }) => workspaceReadMode);

export const workspaceSelectors = {
  isLoading,
  currentConvertedHTML,
  currentId,
  currentLegalTextCount,
  legalTextById,
  nameById,
  urlPath,
  WorkspaceById,
  legalTextsInBetween,
  workspaceReadMode,
};
