import { createSelector } from 'reselect';

import { DocumentNodeState } from '.';

const documentNodeState = (state: any) => state.documentNode as DocumentNodeState;

const documentNode = createSelector(documentNodeState, ({ node }) => node);

export const documentNodeSelectors = {
  documentNode,
};
