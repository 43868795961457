import { Subscription } from 'apollo-client/util/Observable';
import gql from 'graphql-tag';
import { eventChannel } from 'redux-saga';

import { apollo } from '../apollo/client';
import { Project } from '../store/project';

const CREATE_PROJECT_QUERY = gql`
  mutation CREATE_PROJECT($projectInput: ProjectInput!) {
    createProject(projectInput: $projectInput) {
      id
      name
      isLinked
      linkCount
      rootProjectId
      removedAt
      ruleSuites {
        id
        type
        seqNo
        isLinked
        name
        desc
        removedAt
      }
    }
  }
`;

const createProject = async ({
  id,
  name,
  isLinked,
  linkCount,
  ruleSuites,
  rootProjectId,
  createdBy,
  removedAt,
}: Project) => {
  const { data } = await apollo.mutate({
    mutation: CREATE_PROJECT_QUERY,
    variables: {
      projectInput: {
        id,
        name,
        isLinked,
        linkCount,
        ruleSuites,
        rootProjectId,
        createdBy,
        removedAt,
      },
    },
  });
  return { ...data.createProject } as Project;
};

const UPDATE_PROJECT_QUERY = gql`
  mutation UPDATE_PROJECT($projectInput: ProjectInput!) {
    updateProject(projectInput: $projectInput) {
      id
      name
      isLinked
      linkCount
      rootProjectId
      removedAt
      ruleSuites {
        id
        type
        seqNo
        isLinked
        name
        desc
        removedAt
      }
    }
  }
`;

const updateProject = async ({ id, name, isLinked, linkCount, ruleSuites, rootProjectId, removedAt }: Project) => {
  const { data } = await apollo.mutate({
    mutation: UPDATE_PROJECT_QUERY,
    variables: {
      projectInput: {
        id,
        name,
        isLinked,
        linkCount,
        ruleSuites,
        rootProjectId,
        removedAt,
      },
    },
  });
  return { ...data.updateProject } as Project;
};

const ADD_PROJECT_REF_QUERY = gql`
  mutation ADD_PROJECT_REF($refProjectId: String!, $parentProjectId: String!) {
    addProjectRef(refProjectId: $refProjectId, parentProjectId: $parentProjectId) {
      id
      name
      isLinked
      linkCount
      rootProjectId
      removedAt
      ruleSuites {
        id
        type
        seqNo
        isLinked
        name
        desc
        removedAt
      }
    }
  }
`;

const addProjectReference = async (refProjectId: string, parentProjectId: string) => {
  const { data } = await apollo.mutate({
    mutation: ADD_PROJECT_REF_QUERY,
    variables: {
      refProjectId,
      parentProjectId,
    },
  });
  return { ...data.addProjectReference } as Project;
};

const REMOVE_PRO_REF_QUERY = gql`
  mutation REMOVE_PRO_REF($refProjectId: String!, $parentProjectId: String!) {
    removeProjectRef(refProjectId: $refProjectId, parentProjectId: $parentProjectId) {
      id
      name
    }
  }
`;

const removeProjectReference = async (refProjectId: string, parentProjectId: string) => {
  const { data } = await apollo.mutate({
    mutation: REMOVE_PRO_REF_QUERY,
    variables: {
      refProjectId,
      parentProjectId,
    },
  });
  return { ...data.removeProjectReference } as Project;
};

const COPY_PROJECT_QUERY = gql`
  mutation COPY_PROJECT($projectId: String!) {
    copyProject(projectId: $projectId) {
      id
      name
      isLinked
      linkCount
      rootProjectId
      removedAt
      ruleSuites {
        id
        type
        seqNo
        isLinked
        name
        desc
        removedAt
      }
    }
  }
`;

const copyProject = async (projectId: string) => {
  const { data } = await apollo.mutate({
    mutation: COPY_PROJECT_QUERY,
    variables: {
      projectId,
    },
  });
  return { ...data.copyProject } as Project;
};

const DELETE_PROJECT_QUERY = gql`
  mutation DELETE_PROJECT($id: ID!) {
    deleteProject(id: $id) {
      id
      name
      isLinked
      linkCount
      rootProjectId
      removedAt
      ruleSuites {
        id
        type
        seqNo
        isLinked
        name
        desc
        removedAt
      }
    }
  }
`;

const deleteProject = async (id: string) => {
  const { data } = await apollo.mutate({
    mutation: DELETE_PROJECT_QUERY,
    variables: {
      id,
    },
  });
  return { ...data.deleteProject } as Project;
};

const REMOVE_PROJECT_QUERY = gql`
  mutation REMOVE_PROJECT($containerId: String, $projectId: String) {
    removeProject(containerId: $containerId, projectId: $projectId) {
      id
      name
      removedAt
      isLinked
      linkCount
      rootProjectId
      createdBy
      ruleSuites {
        id
        type
        seqNo
        isLinked
        name
        desc
        removedAt
      }
    }
  }
`;

const removeProject = async (containerId: string, projectId: string) => {
  const { data } = await apollo.mutate({
    mutation: REMOVE_PROJECT_QUERY,
    variables: {
      containerId,
      projectId,
    },
  });
  return { ...data.removeProject } as Project;
};

const RESTORE_PROJECT_QUERY = gql`
  mutation RESTORE_PROJECT($containerId: String, $projectId: String) {
    restoreProject(containerId: $containerId, projectId: $projectId) {
      id
      name
      isLinked
      linkCount
      rootProjectId
      removedAt
      ruleSuites {
        id
        type
        seqNo
        isLinked
        name
        desc
        removedAt
      }
    }
  }
`;

const restoreProject = async (containerId: string, projectId: string) => {
  const { data } = await apollo.mutate({
    mutation: RESTORE_PROJECT_QUERY,
    variables: {
      containerId,
      projectId,
    },
  });
  return { ...data.restoreProject } as Project;
};

const ADD_DOCUMENT_QUERY = gql`
  mutation ADD_DOCUMENT($workspaceId: String!, $projectId: String!) {
    addDocument(workspaceId: $workspaceId, projectId: $projectId) {
      id
      name
      isLinked
      linkCount
      rootProjectId
      removedAt
      ruleSuites {
        id
        type
        seqNo
        isLinked
        name
        desc
        removedAt
      }
    }
  }
`;

const addDocument = async (workspaceId: string, projectId: string) => {
  const { data } = await apollo.mutate({
    mutation: ADD_DOCUMENT_QUERY,
    variables: {
      workspaceId,
      projectId,
    },
  });
  return { ...data.addDocument } as Project;
};

const REMOVE_DOCUMENT_QUERY = gql`
  mutation REMOVE_DOCUMENT($workspaceId: String!, $projectId: String!) {
    removeDocument(workspaceId: $workspaceId, projectId: $projectId) {
      id
      name
      isLinked
      linkCount
      rootProjectId
      removedAt
      ruleSuites {
        id
        type
        seqNo
        isLinked
        name
        desc
        removedAt
      }
    }
  }
`;

const removeDocument = async (workspaceId: string, projectId: string) => {
  const { data } = await apollo.mutate({
    mutation: REMOVE_DOCUMENT_QUERY,
    variables: {
      workspaceId,
      projectId,
    },
  });
  return { ...data.removeDocument } as Project;
};

const RESTORE_DOCUMENT_QUERY = gql`
  mutation RESTORE_DOCUMENT($workspaceId: String!, $projectId: String!) {
    restoreDocument(workspaceId: $workspaceId, projectId: $projectId) {
      id
      name
      isLinked
      linkCount
      rootProjectId
      removedAt
      ruleSuites {
        id
        type
        seqNo
        isLinked
        name
        desc
        removedAt
      }
    }
  }
`;

const restoreDocument = async (workspaceId: string, projectId: string) => {
  const { data } = await apollo.mutate({
    mutation: RESTORE_DOCUMENT_QUERY,
    variables: {
      workspaceId,
      projectId,
    },
  });
  return { ...data.restoreDocument } as Project;
};

const GET_PROJECT_QUERY = gql`
  query PROJECT($id: ID!) {
    project(id: $id) {
      id
      name
      isLinked
      linkCount
      createdBy
      rootProjectId
      removedAt
      access {
        publicAccess
        users {
          email
          role
        }
      }
      ruleSuites {
        id
        type
        seqNo
        isLinked
        name
        desc
        removedAt
      }
    }
  }
`;

const getProject = async (id: string) => {
  try {
    let { data } = await apollo.mutate({
      mutation: GET_PROJECT_QUERY,
      variables: {
        id,
      },
    });
    return data === null ? null : ({ ...data.project } as Project);
  } catch (error) {
    console.error(error);
  }
};

const GET_All_NESTED_SUB_PROJECT_IDS_QUERY = gql`
  query GET_SUB_PROJECT_IDS($id: ID!) {
    subProjectIds(id: $id)
  }
`;

const getAllNestedSubProjectIds = async (id: string) => {
  const { data } = await apollo.mutate({
    mutation: GET_All_NESTED_SUB_PROJECT_IDS_QUERY,
    variables: {
      id,
    },
  });
  return { ...data.subProjectIds };
};

const SUBSCRIPTION_PROJECT_UPDATES = gql`
  subscription PROJECT_SUBSCRIPTION($workspaceId: ID!, $projectId: ID!, $projectTreeRootId: ID!) {
    projectUpdates(workspaceId: $workspaceId, projectId: $projectId, projectTreeRootId: $projectTreeRootId) {
      type
      project {
        id
        name
        isLinked
        linkCount
        createdBy
        rootProjectId
        removedAt
        ruleSuites {
          id
          type
          seqNo
          isLinked
          name
          desc
          removedAt
        }
      }
      workspace {
        id
        name
        desc
      }
      segment {
        id
        workspaceId
        type
        startLegalTextId
        endLegalTextId
        rule
        version
      }
      testCase {
        id
        projectId
        workspaceId
        title
        description
        facts
        requiredResults
        unwantedResults
        version
        migrated
      }
    }
  }
`;

let currentProjectSubscription: Subscription;
const subscribeProjectUpdates = (workspaceId: string, projectId: string, projectTreeRootId: string) => {
  return eventChannel((emit) => {
    currentProjectSubscription = apollo
      .subscribe({
        query: SUBSCRIPTION_PROJECT_UPDATES,
        variables: {
          workspaceId,
          projectId,
          projectTreeRootId,
        },
      })
      .subscribe(({ data }) => emit(data.projectUpdates));
    return () => currentProjectSubscription.unsubscribe();
  });
};

const unsubscribeProjectUpdates = async () => {
  currentProjectSubscription.unsubscribe();
};

export const projectService = {
  createProject,
  updateProject,
  addProjectReference,
  removeProjectReference,
  copyProject,
  deleteProject,
  removeProject,
  restoreProject,
  addDocument,
  removeDocument,
  restoreDocument,
  getProject,
  getAllNestedSubProjectIds,
  subscribeProjectUpdates,
  unsubscribeProjectUpdates,
};
