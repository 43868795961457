import { Segment } from './reducer';

var ObjectID = require('bson-objectid');

export const CREATE_NEW_SEGMENT = 'segment/CREATE_NEW_SEGMENT';
export const CREATE_SEGMENT_SUCCESS = 'segment/CREATE_SEGMENET_SUCCESS';
export const OPTIMISTIC_CREATE_SEGMENT = 'segment/OPTIMISTIC_CREATE_SEGMENT';
export const API_ERROR = 'segment/API_ERROR';
export const ACTIVATE_SEGMENT = 'segment/ACTIVATE_SEGMENT';
export const ACTIVATE_RULE = 'segment/ACTIVATE_RULE';
export const CLEAR_ACTIVE_SEGMENT = 'segment/CLEAR_ACTIVE_SEGMENT';
export const SAVE_RULE = 'segment/SAVE_RULE';
export const LOAD_ALL_SEGMENTS = 'segment/LOAD_ALL_SEGMENTS';
export const LOAD_ALL_SEGMENTS_OPTIMISTIC = 'segment/LOAD_ALL_SEGMENTS_OPTIMISTIC';
export const SAVE_SEGMENT_SELECTION = 'segment/SAVE_SEGMENT_SELECTION';
export const OPTIMISTIC_SAVE_SEGMENT = 'segment/OPTIMISTIC_SAVE_SEGMENT';
export const SYNC_SEGMENT_UPDATE = 'segment/SYNC_SEGMENT_UPDATE';
export const SYNC_SEGMENT_DELETE = 'segment/SYNC_SEGMENT_DELETE';
export const PROCESS_SEGMENT_UPDATE_SUBSCRIPTION = 'segment/PROCESS_SEGMENT_UPDATE_SUBSCRIPTION';
export const PROCESS_SEGMENT_DELETE_SUBSCRIPTION = 'segment/PROCESS_SEGMENT_DELETE_SUBSCRIPTION';
export const EXPORT_RULES = 'segment/EXPORT_RULES';
export const EXPORT_XLS = 'segment/EXPORT_XLS';
export const COPY_RULES = 'segment/COPY_RULES';
export const DELETE_SEGMENT = 'segment/DELETE_SEGMENT';
export const OPTIMISTIC_DELETE_SEGMENT = 'segment/OPTIMISTIC_DELETE_SEGMENT';
export const DELETE_SEGMENT_SUCCESS = 'segment/DELETE_SEGMENT_SUCCESS';
export const MASTER_ATOM_LIST_UPDATED = 'segment/MASTER_ATOM_LIST_UPDATED';
export const MOUSE_OVER_SEGMENT = 'segment/MOUSE_OVER_SEGMENT';
export const MOUSE_OUT_SEGMENT = 'segment/MOUSE_OUT_SEGMENT';
export const LAST_LEGAL_TEXT_ID = 'segment/LAST_LEGAL_TEXT_ID';
export const RESIZING_SEGMENT = 'segment/RESIZING_SEGMENT';
export const CLEAR_RESIZING_SEGMENT = 'segment/CLEAR_RESIZING_SEGMENT';
export const COLLAPSE_ALL_UNSELECTED = 'segment/COLLAPSE_ALL_UNSELECTED';

export const createNewSegment = (
  workspaceId: string,
  startLegalTextId: string,
  endLegalTextId?: string,
  type = 'selected',
  rule?: string
) => ({
  type: CREATE_NEW_SEGMENT,
  payload: {
    id: ObjectID().toString(),
    workspaceId,
    type,
    startLegalTextId,
    endLegalTextId: endLegalTextId || startLegalTextId,
    rule,
    version: 1,
  },
});

export const createSegmentSuccess = (segment: Segment) => ({
  type: CREATE_SEGMENT_SUCCESS,
  payload: {
    segment,
  },
});

export const createSegmentOptimistic = (segment: Segment, type: string) => ({
  type: OPTIMISTIC_CREATE_SEGMENT,
  payload: {
    type,
    segment,
  },
});

export const activateSegment = (id: string) => ({
  type: ACTIVATE_SEGMENT,
  payload: {
    id,
  },
});

export const activateRule = (id: string) => ({
  type: ACTIVATE_RULE,
  payload: {
    id,
  },
});

export const apiError = (error: any) => ({
  type: API_ERROR,
  payload: {
    error,
  },
});

export const saveRule = (id: string, rule: string) => ({
  type: SAVE_RULE,
  payload: {
    id,
    rule,
  },
});

export const loadAllSegments = (projectId: string) => ({
  type: LOAD_ALL_SEGMENTS,
  payload: {
    projectId,
  },
});

export const loadAllSegmentsOptimistic = (segments: Segment[]) => ({
  type: LOAD_ALL_SEGMENTS_OPTIMISTIC,
  payload: {
    segments,
  },
});

export const saveSegmentSelection = (id: string, startLegalTextId: string, endLegalTextId: string) => ({
  type: SAVE_SEGMENT_SELECTION,
  payload: {
    id,
    startLegalTextId,
    endLegalTextId,
  },
});

export const saveSegmentOptimistic = (segment: Segment) => ({
  type: OPTIMISTIC_SAVE_SEGMENT,
  payload: {
    segment,
  },
});

export const syncSegmentUpdate = (segment: Segment) => ({
  type: SYNC_SEGMENT_UPDATE,
  payload: {
    segment,
  },
});

export const syncSegmentDelete = (id: string) => ({
  type: SYNC_SEGMENT_DELETE,
  payload: {
    id,
  },
});

export const ProcessSegmentUpdate = (segment: Segment) => ({
  type: PROCESS_SEGMENT_UPDATE_SUBSCRIPTION,
  payload: {
    segment,
  },
});
export const ProcessSegmentDelete = (segment: Segment) => ({
  type: PROCESS_SEGMENT_DELETE_SUBSCRIPTION,
  payload: {
    segment,
  },
});

export const exportRules = () => ({
  type: EXPORT_RULES,
});

export const exportXls = () => ({
  type: EXPORT_XLS,
});

export const copyRules = () => ({
  type: COPY_RULES,
});

export const deleteSegment = (id: string) => ({
  type: DELETE_SEGMENT,
  payload: {
    id,
  },
});

export const deleteSegmentOptimistic = (id: string) => ({
  type: OPTIMISTIC_DELETE_SEGMENT,
  payload: {
    id,
  },
});

export const segmentDeletedSuccess = (id: string) => ({
  type: DELETE_SEGMENT_SUCCESS,
  payload: {
    id,
  },
});

export const clearActiveSegment = () => ({
  type: CLEAR_ACTIVE_SEGMENT,
});

export const masterAtomListUpdated = (masterAtomList: string[]) => ({
  type: MASTER_ATOM_LIST_UPDATED,
  payload: {
    masterAtomList,
  },
});

export const mouseOverSegment = (hoveredSegmentId: string) => ({
  type: MOUSE_OVER_SEGMENT,
  payload: {
    hoveredSegmentId,
  },
});

export const mouseOutSegment = () => ({
  type: MOUSE_OUT_SEGMENT,
});

export const lastLegalTextId = (lastLegalTextId: string) => ({
  type: LAST_LEGAL_TEXT_ID,
  payload: {
    lastLegalTextId,
  },
});

export const resizingSegment = (resizingSegmentId: string) => ({
  type: RESIZING_SEGMENT,
  payload: {
    resizingSegmentId,
  },
});

export const clearResizingSegment = () => ({
  type: CLEAR_RESIZING_SEGMENT,
});

export const collapseAllUnselected = () => ({
  type: COLLAPSE_ALL_UNSELECTED,
});
