import { navigate } from '@gatsbyjs/reach-router';
import { call, debounce, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { dashboardService as service } from '../../services';
import { loadAllProjects, loadProject } from '../project';
import {
  ADD_PROJECT,
  COLLABORATE_PROJECT_REQUEST,
  CREATE_DASHBOARD_REQUEST,
  FETCH_LOAD_PROJECT,
  IMPORT_PROJECT_REQUEST,
  LOAD_DASHBOARD_REQUEST,
  SUBSCRIBE_DASHBOARD_UPDATES,
  UNSUBSCRIBE_DASHBOARD_UPDATES,
  UPDATE_DASHBOARD_NAME,
  addProjectOptimistic,
  apiError,
  collaborateProjectSuccess,
  createDashboardOptimistic,
  dashboardLoaded,
  importProjectSuccess,
  loadDashboard,
  loadUserDashboardSuccess,
  syncDashboardUpdate,
} from './actions';
import { Dashboard } from './reducer';
import { dashboardSelector } from './selectors';

const ObjectID = require('bson-objectid');

function* loadDashboardReqSaga({ payload }: any): any {
  const { id, email } = payload;
  const dashboard: Dashboard = yield call(service.getDashboard, id, email);
  if (id) {
    yield put(dashboardLoaded(dashboard));
    yield put(loadAllProjects(id));
  } else {
    yield put(loadUserDashboardSuccess(dashboard));
    const email = yield select(dashboardSelector.activeUserEmailId);
    yield put(loadAllProjects(email));
  }
}

function* addProjectSaga({ payload }: any) {
  const { dashboardId, projectId } = payload;
  try {
    yield put(addProjectOptimistic(dashboardId, projectId));
    yield call(service.addProject, dashboardId, projectId);
    yield put(loadProject(projectId));
  } catch (e) {
    console.error(e);
    yield put(apiError(e));
  }
}

function* fetchLoadProjectSaga({ payload }: any): any {
  const { dashboardId } = payload;
  const dashboard = yield call(service.getDashboard, dashboardId);
  const projects: string[] = dashboard.projects;
  // there is only one project
  yield put(loadProject(projects[0]));
}

function* createDashboardReqSaga({ payload }: any) {
  const { name } = payload;
  try {
    const newDashboard = {
      id: ObjectID().toString(),
      name: name,
      projects: [],
      createdDate: new Date(),
    } as Dashboard;

    yield put(createDashboardOptimistic(newDashboard));
    navigate(`/dashboard/${newDashboard.id}`);
    yield call(service.createDashboard, newDashboard);
  } catch (e) {
    console.error(e);
    yield put(apiError(e));
  }
}

function* collaborateProjectSaga({ payload }: any) {
  const { dashboardId, projectId } = payload;
  yield call(service.addProjectAndDependents, dashboardId, projectId);
  yield put(collaborateProjectSuccess());
  yield put(loadDashboard(dashboardId));
}

function* importProjectSaga({ payload }: any) {
  try {
    const { dashboardId, files } = payload;
    yield call(service.processImportFiles, dashboardId, files);
    yield put(importProjectSuccess());
    yield put(loadDashboard(dashboardId));
  } catch (e) {
    console.error(e);
    yield put(apiError(e));
  }
}

function* updateDashboardNameSaga({ payload }: any) {
  const { id, name } = payload;
  yield call(service.updateDashboard, id, name);
}

function* subscribeSaga({ payload }: any): any {
  const apolloChannel = yield call(service.subscribeDashboardUpdates, payload.dashboardId);
  while (true) {
    const data = yield take(apolloChannel);
    if (data.type === 'UPSERT') {
      const { dashboard } = data;
      yield put(syncDashboardUpdate(dashboard));
    }
  }
}

function* unsubscribeSaga() {
  yield call(service.unsubscribeDashboardUpdates);
}

export const dashboardSaga = [
  takeLatest(CREATE_DASHBOARD_REQUEST, createDashboardReqSaga),
  takeEvery(ADD_PROJECT, addProjectSaga),
  takeLatest(LOAD_DASHBOARD_REQUEST, loadDashboardReqSaga),
  takeLatest(FETCH_LOAD_PROJECT, fetchLoadProjectSaga),
  debounce(1000, UPDATE_DASHBOARD_NAME, updateDashboardNameSaga),
  takeEvery(COLLABORATE_PROJECT_REQUEST, collaborateProjectSaga),
  takeEvery(IMPORT_PROJECT_REQUEST, importProjectSaga),
  takeEvery(SUBSCRIBE_DASHBOARD_UPDATES, subscribeSaga),
  takeEvery(UNSUBSCRIBE_DASHBOARD_UPDATES, unsubscribeSaga),
];
