import { createSelector } from 'reselect';

import { AuthState } from './reducer';

const getAuthState = (state: any) => state.auth as AuthState;

const enableAuth = createSelector(getAuthState, ({ enableAuth }) => enableAuth);

const authStateFinalized = createSelector(getAuthState, ({ authStateFinalized }) => authStateFinalized);

const isLoggedIn = createSelector(getAuthState, ({ isLoggedIn }) => isLoggedIn);

const userId = createSelector(getAuthState, ({ userId }) => userId);

export const authSelectors = {
  enableAuth,
  authStateFinalized,
  isLoggedIn,
  userId,
};
