import gql from 'graphql-tag';

import { apollo } from '../apollo/client';

const GET_SETTING_QUERY = gql`
  query GET_SETTING($userId: String!, $workspaceId: String!) {
    setting(workspaceId: $workspaceId, userId: $userId) {
      id
      workspaceId
      userId
      setting
    }
  }
`;

const getSetting = async (userId: string, workspaceId: string) => {
  const { data } = await apollo.query({
    query: GET_SETTING_QUERY,
    variables: {
      userId,
      workspaceId,
    },
  });
  return { ...data.setting };
};

const SAVE_SETING_QUERY = gql`
  mutation SAVE_SETING_QUERY($settingInput: SettingInput!) {
    saveSetting(settingInput: $settingInput) {
      id
      userId
      workspaceId
      setting
    }
  }
`;
const saveSetting = async (userId: string, workspaceId: string, setting: string) => {
  const { data } = await apollo.mutate({
    mutation: SAVE_SETING_QUERY,
    variables: {
      settingInput: {
        workspaceId,
        userId,
        setting,
      },
    },
  });
  return { ...data.saveSetting };
};

export const settingService = {
  getSetting,
  saveSetting,
};
