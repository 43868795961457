import { navigate } from '@gatsbyjs/reach-router';
import { call, put, takeEvery, takeLatest, throttle } from 'redux-saga/effects';

import { workspaceService as service } from '../../services';
import { projectService } from '../../services';
import { projectRefreshed } from '../project/actions';
import { Project } from '../project/reducer';
import {
  CREATE_WORKSPACE_REQUEST,
  CREATE_WORKSPACE_SUCCESS,
  DELETE_WORKSPACE,
  LOAD_WORKSPACE_REQUEST,
  UPDATE_WORKSPACE_DESC,
  UPDATE_WORKSPACE_NAME,
  apiError,
  deleteWorkspaceOptimistic,
  deleteWorkspaceSuccess,
  updateWorkspaceDescOptimistic,
  updateWorkspaceDescSuccess,
  updateWorkspaceNameOptimistic,
  updateWorkspaceNameSuccess,
  workspaceCreated,
  workspaceLoaded,
} from './actions';

function* createWorkspaceRequest({ payload }: any) {
  const { activeDashboardId, projectId } = payload;
  try {
    const { id, convertedHTML, legalTextCount, name, desc, urlPath, migrated } = yield call(
      service.createWorkspace,
      payload.file
    );
    yield put(
      workspaceCreated(id, convertedHTML, legalTextCount, name, desc, urlPath, migrated, projectId, activeDashboardId)
    );
  } catch (e) {
    console.error(e);
    yield put(apiError(e));
  }
}

function* createWorkspaceSuccess({ payload }: any) {
  const { id, projectId, activeDashboardId } = payload;
  const project: Project = yield call(projectService.addDocument, id, projectId);
  yield put(projectRefreshed(project, id));
  if (activeDashboardId) {
    navigate(`/dashboard/${activeDashboardId}/project/${projectId}/workspace/${id}`);
  } else {
    navigate(`/project/${projectId}/workspace/${id}`);
  }
}

function* loadWorkspace({ payload }: any) {
  try {
    const { id, name, desc, convertedHTML, legalTextCount, urlPath, migrated, segments, testCases } = yield call(
      service.getWorkspace,
      payload.id
    );
    yield put(workspaceLoaded(id, convertedHTML, legalTextCount, name, desc, urlPath, migrated, segments, testCases));
  } catch (e) {
    console.error(e);
    yield put(apiError(e));
  }
}

function* deleteWorkspaceSaga({ payload: { testCaseId } }: any) {
  yield put(deleteWorkspaceOptimistic(testCaseId));
  const data = '';
  yield put(deleteWorkspaceSuccess(data));
}

function* updateWorkspaceDescSaga({ payload }: any) {
  const { id, desc } = payload;
  const currentWorkspace = yield call(service.getWorkspace, id);
  yield put(updateWorkspaceDescOptimistic(currentWorkspace, desc));
  currentWorkspace.desc = desc;
  const updatedWorkspace = yield call(service.updateWorkspace, currentWorkspace);
  yield put(updateWorkspaceDescSuccess(updatedWorkspace));
}

function* updateWorkspaceNameSaga({ payload }: any) {
  const { id, name } = payload;
  const currentWorkspace = yield call(service.getWorkspace, id);
  yield put(updateWorkspaceNameOptimistic(currentWorkspace, name));
  currentWorkspace.name = name;
  const updatedWorkspace = yield call(service.updateWorkspace, currentWorkspace);
  yield put(updateWorkspaceNameSuccess(updatedWorkspace));
}

export const workspaceSaga = [
  takeLatest(CREATE_WORKSPACE_REQUEST, createWorkspaceRequest),
  takeLatest(CREATE_WORKSPACE_SUCCESS, createWorkspaceSuccess),
  takeLatest(LOAD_WORKSPACE_REQUEST, loadWorkspace),
  takeEvery(DELETE_WORKSPACE, deleteWorkspaceSaga),
  throttle(500, UPDATE_WORKSPACE_DESC, updateWorkspaceDescSaga),
  throttle(500, UPDATE_WORKSPACE_NAME, updateWorkspaceNameSaga),
  //debounce(1000, UPDATE_WORKSPACE_DESC, updateWorkspaceDescSaga),
  //debounce(1000, UPDATE_WORKSPACE_NAME, updateWorkspaceNameSaga),
];
