import { Subscription } from 'apollo-client/util/Observable';
import gql from 'graphql-tag';
import { eventChannel } from 'redux-saga';

import { apollo } from '../apollo/client';
import { Dashboard } from '../store/dashboard';

const CREATE_DASHBOARD_QUERY = gql`
  mutation CREATE_DASHBOARD_QUERY($dashboardInput: DashboardInput!) {
    createDashboard(dashboardInput: $dashboardInput) {
      id
      name
      projects
    }
  }
`;
const createDashboard = async ({ id, name, projects }: Dashboard) => {
  const { data } = await apollo.mutate({
    mutation: CREATE_DASHBOARD_QUERY,
    variables: {
      dashboardInput: {
        id,
        name,
        projects,
      },
    },
  });
  return { ...data.createDashboard } as Dashboard;
};

const ADD_PROJECT_TO_DASHBOARD = gql`
  mutation ADD_PROJECT_TO_DASHBOARD($dashboardId: String!, $projectId: String!) {
    addProject(dashboardId: $dashboardId, projectId: $projectId) {
      id
      name
      projects
    }
  }
`;
const addProject = async (dashboardId: string, projectId: string) => {
  const { data } = await apollo.mutate({
    mutation: ADD_PROJECT_TO_DASHBOARD,
    variables: {
      dashboardId,
      projectId,
    },
  });
  return { ...data.addProject } as Dashboard;
};

const ADD_PROJECTS_TO_DASHBOARD_QUERY = gql`
  mutation ADD_PROJECTS_TO_DASHBOARD($projectIds: [String!]!, $dashboardId: String!) {
    addProjects(projectIds: $projectIds, dashboardId: $dashboardId) {
      id
      name
      projects
    }
  }
`;
const addProjects = async (projectIds: string[], dashboardId: string) => {
  const { data } = await apollo.mutate({
    mutation: ADD_PROJECTS_TO_DASHBOARD_QUERY,
    variables: {
      projectIds,
      dashboardId,
    },
  });
  return { ...data.addProjects } as Dashboard;
};

const ADD_PROJECT_AND_DEPENDENTS_TO_DASHBOARD = gql`
  mutation ADD_PROJECT_AND_DEPENDENTS_TO_DASHBOARD($dashboardId: String!, $projectId: String!) {
    addProjectAndDependents(dashboardId: $dashboardId, projectId: $projectId) {
      id
      name
      projects
    }
  }
`;
const addProjectAndDependents = async (dashboardId: string, projectId: string) => {
  const { data } = await apollo.mutate({
    mutation: ADD_PROJECT_AND_DEPENDENTS_TO_DASHBOARD,
    variables: {
      dashboardId,
      projectId,
    },
  });
  return { ...data.addProject } as Dashboard;
};

const IMPORT_PROJECT_ADD_PROJECTS_TO_DASHBOARD = gql`
  mutation IMPORT_PROJECT_ADD_PROJECTS_TO_DASHBOARD($dashboardId: String!, $files: [String!]!) {
    processImportFiles(dashboardId: $dashboardId, files: $files) {
      id
      name
      projects
    }
  }
`;
const processImportFiles = async (dashboardId: string, files: String[]) => {
  const { data } = await apollo.mutate({
    mutation: IMPORT_PROJECT_ADD_PROJECTS_TO_DASHBOARD,
    variables: {
      dashboardId,
      files,
    },
  });
  return { ...data.addProject } as Dashboard;
};

const UPDATE_DASHBOARD = gql`
  mutation UPDATE_DASHBOARD($input: DashboardInput!) {
    updateDashboard(dashboardInput: $input) {
      id
      name
      projects
    }
  }
`;
const updateDashboard = async (id: string, name: string) => {
  const { data } = await apollo.mutate({
    mutation: UPDATE_DASHBOARD,
    variables: {
      input: {
        id,
        name,
      },
    },
  });
  return { ...data.updateDashboard } as Dashboard;
};

const GET_DASHBOARD_QUERY = gql`
  query GET_DASHBOARD($id: ID, $email: String) {
    dashboard(id: $id, email: $email) {
      id
      name
      projects
    }
  }
`;
const getDashboard = async (id?: string, email?: string) => {
  try {
    const { data } = await apollo.mutate({
      mutation: GET_DASHBOARD_QUERY,
      variables: {
        email,
        id,
      },
    });
    return data === null ? null : { ...data.dashboard };
  } catch (error) {
    console.error(error);
  }
};

const GET_All_DASHBOARD_BY_PROJECT_ID_QUERY = gql`
  query GET_All_DASHBOARD_BY_PROJECT_ID($projectId: ID!) {
    dashboardsByProjectId(projectId: $projectId)
  }
`;
const dashboardsByProjectId = async (projectId: string) => {
  const { data } = await apollo.mutate({
    mutation: GET_All_DASHBOARD_BY_PROJECT_ID_QUERY,
    variables: {
      projectId,
    },
  });
  return { ...data.dashboardsByProjectId };
};

const SUBSCRIPTION_DASHBOARD_UPDATES = gql`
  subscription DASHBOARD_SUBSCRIPTION($dashboardId: ID!) {
    dashboardUpdates(dashboardId: $dashboardId) {
      type
      dashboard {
        id
        name
        projects
      }
    }
  }
`;

let currentDashboardSubscription: Subscription;
const subscribeDashboardUpdates = (dashboardId: string) => {
  return eventChannel((emit) => {
    currentDashboardSubscription = apollo
      .subscribe({
        query: SUBSCRIPTION_DASHBOARD_UPDATES,
        variables: {
          dashboardId,
        },
      })
      .subscribe(({ data }) => emit(data.dashboardUpdates));
    return () => currentDashboardSubscription.unsubscribe();
  });
};

const unsubscribeDashboardUpdates = async () => {
  currentDashboardSubscription.unsubscribe();
};

export const dashboardService = {
  getDashboard,
  createDashboard,
  addProject,
  addProjects,
  addProjectAndDependents,
  processImportFiles,
  updateDashboard,
  dashboardsByProjectId,
  subscribeDashboardUpdates,
  unsubscribeDashboardUpdates,
};
