import { Subscription } from 'apollo-client/util/Observable';
import gql from 'graphql-tag';
import { eventChannel } from 'redux-saga';

import { apollo } from '../apollo/client';
import { TestCase } from '../store/testCase';

const CREATE_TEST_CASE_QUERY = gql`
  mutation CREATE_TEST_CASE($input: TestCaseInput!) {
    createTestCase(testCaseInput: $input) {
      id
      projectId
      workspaceId
      title
      description
      facts
      requiredResults
      unwantedResults
      version
    }
  }
`;

const createTestCase = async ({
  id,
  projectId,
  workspaceId,
  title,
  description,
  facts,
  requiredResults,
  unwantedResults,
  version,
}: TestCase) => {
  const { data } = await apollo.mutate({
    mutation: CREATE_TEST_CASE_QUERY,
    variables: {
      input: {
        id,
        projectId,
        workspaceId,
        title,
        description,
        facts,
        requiredResults,
        unwantedResults,
        version,
      },
    },
  });
  return { ...data.createTestCase } as TestCase;
};

const UPDATE_TESTCASE_QUERY = gql`
  mutation UPDATE_TEST_CASE($input: TestCaseInput!) {
    updateTestCase(testCaseInput: $input) {
      id
      projectId
      workspaceId
      title
      description
      facts
      requiredResults
      unwantedResults
      version
    }
  }
`;
const updateTestCase = async ({
  id,
  projectId,
  workspaceId,
  title,
  description,
  facts,
  requiredResults,
  unwantedResults,
  version,
}: TestCase) => {
  const { data } = await apollo.mutate({
    mutation: UPDATE_TESTCASE_QUERY,
    variables: {
      input: {
        id,
        projectId,
        workspaceId,
        title,
        description,
        facts,
        requiredResults,
        unwantedResults,
        version,
      },
    },
  });
  return { ...data.updateTestCase } as TestCase;
};

const DELETE_TEST_CASE_QUERY = gql`
  mutation DELETE_TEST_CASE($id: ID!) {
    deleteTestCase(id: $id) {
      id
    }
  }
`;
const deleteTestCase = async (id: string) => {
  const { data } = await apollo.mutate({
    mutation: DELETE_TEST_CASE_QUERY,
    variables: {
      id,
    },
  });
  return data.deleteTestCase.id;
};

const GET_ALL_TESTCASES_QUERY = gql`
  query GET_ALL_TESTCASES_BY_PROJECT_ID($projectId: String!) {
    testCasesByProject(projectId: $projectId) {
      id
      projectId
      workspaceId
      title
      description
      facts
      requiredResults
      unwantedResults
      version
    }
  }
`;

const getAllTestcases = async (projectId: string) => {
  const { data } = await apollo.mutate({
    mutation: GET_ALL_TESTCASES_QUERY,
    variables: {
      projectId,
    },
  });
  return { ...data.testCasesByProject };
};

const SUBSCRIPTION_TESTCASE_UPDATES = gql`
  subscription TEST_CASE_UPDATES($workspaceId: ID!) {
    testCaseUpdates(workspaceId: $workspaceId) {
      type
      testCase {
        id
        projectId
        workspaceId
        title
        description
        facts
        requiredResults
        unwantedResults
        version
      }
    }
  }
`;

let currentTestcaseSubscription: Subscription;
const subscribeTestcaseUpdates = (workspaceId: string) => {
  return eventChannel((emit) => {
    currentTestcaseSubscription = apollo
      .subscribe({
        query: SUBSCRIPTION_TESTCASE_UPDATES,
        variables: {
          workspaceId,
        },
      })
      .subscribe(({ data }) => emit(data.testCaseUpdates));
    return () => currentTestcaseSubscription.unsubscribe();
  });
};

const unsubscribeTestcaseUpdates = async () => {
  currentTestcaseSubscription.unsubscribe();
};

export const testCaseService = {
  createTestCase,
  updateTestCase,
  deleteTestCase,
  getAllTestcases,
  subscribeTestcaseUpdates,
  unsubscribeTestcaseUpdates,
};
