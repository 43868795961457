import * as _ from 'lodash';

import { LOAD_WORKSPACE_REQUEST } from '../workspace';
import {
  ACTIVATE_RULE,
  ACTIVATE_SEGMENT,
  CLEAR_ACTIVE_SEGMENT,
  CLEAR_RESIZING_SEGMENT,
  LAST_LEGAL_TEXT_ID,
  LOAD_ALL_SEGMENTS,
  LOAD_ALL_SEGMENTS_OPTIMISTIC,
  MASTER_ATOM_LIST_UPDATED,
  MOUSE_OUT_SEGMENT,
  MOUSE_OVER_SEGMENT,
  OPTIMISTIC_CREATE_SEGMENT,
  OPTIMISTIC_DELETE_SEGMENT,
  OPTIMISTIC_SAVE_SEGMENT,
  RESIZING_SEGMENT,
  SYNC_SEGMENT_DELETE,
  SYNC_SEGMENT_UPDATE,
} from './actions';

export interface Segment {
  id: string;
  workspaceId: string;
  type: 'selected' | 'collapsed' | 'unselected';
  startLegalTextId: string;
  endLegalTextId: string;
  rule?: string;
  version: number;
}

export interface SegmentState {
  activeSegmentId: string;
  hoveredSegmentId: string;
  resizingSegmentId: string;
  lastLegalTextId: string;
  segmentsMap: {
    [segmentId: string]: Segment;
  };
  masterAtomList: string[];
  loading: boolean;
}

const initialState: SegmentState = {
  activeSegmentId: '',
  hoveredSegmentId: '',
  lastLegalTextId: '',
  resizingSegmentId: '',
  segmentsMap: {},
  masterAtomList: ['Atom'],
  loading: false,
};

export interface Action {
  type: string;
  payload?: any;
}

export const segmentReducer = (state: SegmentState = initialState, action: Action): SegmentState => {
  const { type, payload } = action;

  switch (type) {
    case OPTIMISTIC_CREATE_SEGMENT: {
      const { segment } = payload;
      const activate = segment.type === 'selected' && {
        activeSegmentId: segment.id,
      };

      return {
        ...state,
        ...activate,
        segmentsMap: {
          ...state.segmentsMap,
          [segment.id]: {
            ...cloneSegment(segment),
          },
        },
      };
    }

    case ACTIVATE_RULE:
    case ACTIVATE_SEGMENT:
      return {
        ...state,
        activeSegmentId: payload.id,
        hoveredSegmentId: '',
        resizingSegmentId: '',
      };

    case CLEAR_ACTIVE_SEGMENT:
      return {
        ...state,
        activeSegmentId: '',
        resizingSegmentId: '',
      };

    case LOAD_WORKSPACE_REQUEST:
      return {
        ...initialState,
      };

    case LOAD_ALL_SEGMENTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOAD_ALL_SEGMENTS_OPTIMISTIC:
      const { segments }: { segments: Segment[] } = payload;
      const loadedMap: any = {};
      segments.forEach((segment) => {
        loadedMap[segment.id] = {
          ...cloneSegment(segment),
        };
      });
      return {
        ...state,
        loading: false,
        segmentsMap: {
          ...loadedMap,
        },
      };

    case SYNC_SEGMENT_UPDATE:
    case OPTIMISTIC_SAVE_SEGMENT:
      const { segment } = payload;

      return {
        ...state,
        segmentsMap: {
          ...state.segmentsMap,
          [segment.id]: {
            ...cloneSegment(segment),
          },
        },
      };

    case OPTIMISTIC_DELETE_SEGMENT:
    case SYNC_SEGMENT_DELETE:
      const { id } = payload;
      return {
        ...state,
        activeSegmentId: state.activeSegmentId === id ? '' : state.activeSegmentId,
        segmentsMap: _.omit(state.segmentsMap, id),
      };

    case MASTER_ATOM_LIST_UPDATED:
      const { masterAtomList } = payload;
      return {
        ...state,
        masterAtomList,
      };

    case MOUSE_OVER_SEGMENT: {
      const { hoveredSegmentId } = payload;

      return {
        ...state,
        hoveredSegmentId,
      };
    }

    case MOUSE_OUT_SEGMENT: {
      return {
        ...state,
        hoveredSegmentId: '',
      };
    }

    case LAST_LEGAL_TEXT_ID: {
      const { lastLegalTextId } = payload;
      return {
        ...state,
        lastLegalTextId,
      };
    }

    case RESIZING_SEGMENT: {
      const { resizingSegmentId } = payload;
      return {
        ...state,
        resizingSegmentId,
      };
    }

    case CLEAR_RESIZING_SEGMENT: {
      return {
        ...state,
        resizingSegmentId: '',
      };
    }

    default:
      return state;
  }
};

const cloneSegment = ({ id, workspaceId, type, startLegalTextId, endLegalTextId, rule, version }: Segment) => ({
  id,
  workspaceId,
  type,
  startLegalTextId,
  endLegalTextId,
  rule,
  version,
});
