import { createSelector } from 'reselect';

import { TOOLBAR_HEIGHT } from '../../utils/constants';
import { Segment, segmentSelectors } from '../segment';
import { GridState } from './reducer';

const gridState = (state: any) => state.grid as GridState;

const gridMap = createSelector(gridState, ({ gridMap }) => gridMap);

const topPositionById = (segmentId: string) =>
  createSelector(
    gridState,
    ({ gridMap }) =>
      (gridMap[segmentId] &&
        gridMap[segmentId].top !== null && {
          top: (gridMap[segmentId].top || 0) + TOOLBAR_HEIGHT,
        }) ||
      {}
  );

const gridById = (id: string) => createSelector(gridMap, (gridMap: any) => gridMap[id]);

const lastBottomPosition = createSelector(
  gridState,
  segmentSelectors.lastSelectedSegment,
  ({ gridMap }: GridState, lastSegment: Segment | null) => {
    return (
      (lastSegment && gridMap[lastSegment.id] && gridMap[lastSegment.id].top + gridMap[lastSegment.id].height) || 0
    );
  }
);

const firstLineNumber = (segmentId: string) =>
  createSelector(
    gridState,
    ({ gridMap }: GridState) => (gridMap[segmentId] && gridMap[segmentId].firstLineNumber) || 1
  );

const snapGutterHeight = createSelector(gridState, ({ snapGutterHeight }: GridState) => snapGutterHeight || 0);

const firstVisibleSegment = createSelector(
  gridState,
  segmentSelectors.selectedSegments,
  ({ visibleSegmentIds }: GridState, segments: Segment[]) => {
    return segments.find((s) => visibleSegmentIds.find((id) => id === s.id));
  }
);

const isActiveSegmentVisible = createSelector(
  gridState,
  segmentSelectors.activeSegmentId,
  ({ visibleSegmentIds }: GridState, activeSegmentId: string) => visibleSegmentIds.includes(activeSegmentId)
);

const isActiveRuleVisible = createSelector(gridState, ({ isActiveRuleVisible }) => isActiveRuleVisible);

const rulesPanelWidth = createSelector(gridState, ({ rulesPanelWidth }) => rulesPanelWidth);

const rulesPanelHeight = createSelector(gridState, ({ rulesPanelHeight }) => rulesPanelHeight);

const rulesPanelDefaultHeight = createSelector(gridState, ({ rulesPanelDefaultHeight }) => rulesPanelDefaultHeight);

const segmentPanelWidth = createSelector(gridState, ({ segmentPanelWidth }) => segmentPanelWidth);

const split1ScrollPosition = createSelector(gridState, ({ split1ScrollPosition }) => split1ScrollPosition);

export const gridSelectors = {
  gridMap,
  topPositionById,
  lastBottomPosition,
  firstLineNumber,
  snapGutterHeight,
  firstVisibleSegment,
  isActiveSegmentVisible,
  isActiveRuleVisible,
  gridById,
  rulesPanelWidth,
  rulesPanelHeight,
  rulesPanelDefaultHeight,
  segmentPanelWidth,
  split1ScrollPosition,
};
