import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { accessReducer, accessSaga } from './access';
import { authReducer, authSaga } from './auth';
import { dashboardReducer, dashboardSaga } from './dashboard';
import { documentNodeReducer, documentNodeSaga } from './documentNode';
import { projectReducer, projectSaga } from './project';
import { gridReducer } from './rulesGrid';
import { gridSaga } from './rulesGrid';
import { segmentReducer } from './segment';
import { segmentSaga } from './segment/saga';
import { settingReducer, settingSaga } from './setting';
import { testReducer, testSaga } from './test';
import { testCaseReducer } from './testCase';
import { testCaseSaga } from './testCase';
import { workspaceReducer } from './workspace';
import { workspaceSaga } from './workspace';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  combineReducers({
    dashboard: dashboardReducer,
    project: projectReducer,
    workspace: workspaceReducer,
    segment: segmentReducer,
    grid: gridReducer,
    testCase: testCaseReducer,
    setting: settingReducer,
    auth: authReducer,
    access: accessReducer,
    documentNode: documentNodeReducer,
    test: testReducer,
  }),
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

export const runSagaMiddleware = () => {
  sagaMiddleware.run(rootSaga);
};

function* rootSaga() {
  yield all([
    ...dashboardSaga,
    ...projectSaga,
    ...segmentSaga,
    ...workspaceSaga,
    ...gridSaga,
    ...testCaseSaga,
    ...settingSaga,
    ...authSaga,
    ...accessSaga,
    ...documentNodeSaga,
    ...testSaga,
  ]);
}
