import { call, select, takeEvery } from 'redux-saga/effects';
import { put } from 'redux-saga/effects';

import { settingService } from '../../services';
import { authSelectors } from '../auth';
import { LOAD_DASHBOARD_REQUEST } from '../dashboard';
import { projectSelectors } from '../project';
import {
  ACTIVATE_TEST_CASE,
  SORT_TEST_CASES,
  START_WATCH_MODE,
  STOP_WATCH_MODE,
  SYNC_TEST_CASE_DELETE,
} from '../testCase';
import { LOAD_WORKSPACE_REQUEST, workspaceSelectors } from '../workspace';
import {
  ADD_PROJECT_INITIATOR,
  AUTO_SUGGEST_TOGGLE,
  CLOSE_PROJECT_DRAWER,
  CLOSE_RESULTS_PANEL,
  CLOSE_RULES_PANEL,
  CLOSE_SEGMENT_PANEL,
  CLOSE_TEST_CASE_DRAWER,
  CLOSE_TEST_CASE_PANEL,
  COLLAPSE_SEGMENT,
  DASHBOARD_URL_SAVE_MSG,
  DELETE_TEST_CASE_SETTING,
  HIGHLIGHT_PROJECT_ID,
  MASTER_PROJECT_ID,
  OPEN_RESULTS_PANEL,
  OPEN_RULES_PANEL,
  OPEN_SEGMENT_PANEL,
  OPEN_TEST_CASE_PANEL,
  SET_TEST_CASE_SETTING,
  SYNTAX_HIGHLIGHT_TOGGLE,
  TEST_CASE_AUTO_SUGGEST_TOGGLE,
  TEST_CASE_WORD_WRAP_TOGGLE,
  TOGGLE_AUTO_SCROLL,
  TOGGLE_DESCRIPTION,
  TOGGLE_FACTS,
  TOGGLE_REQUIRED_RESULTS,
  TOGGLE_UNWANTED_RESULTS,
  UNCOLLAPSE_SEGMENT,
  UPDATE_SPLIT1_SIZE,
  UPDATE_SPLIT2_SIZE,
  UPDATE_SPLIT3_SIZE,
  WORD_WRAP_TOGGLE,
  dashboardSettingLoaded,
  workspaceSettingLoaded,
} from './actions';
import { SettingState } from './reducer';
import { settingSelectors } from './selectors';

function* loadSettingSaga({ payload: { id, isAuthenticated, userId } }: any): any {
  if (isAuthenticated) {
    const result = yield call(settingService.getSetting, userId, id);
    if (result && result.setting) {
      yield put(workspaceSettingLoaded(JSON.parse(result.setting)));
    } else {
      yield put(workspaceSettingLoaded({} as SettingState));
    }
    return;
  }
  const setting = localStorage.getItem(`${id}/setting`);
  const parsed = ((setting && (JSON.parse(setting) as SettingState)) || {}) as SettingState;
  yield put(workspaceSettingLoaded(parsed));
}

function* saveSetting(): any {
  const workspaceId = yield select(workspaceSelectors.currentId);
  const setting: SettingState = yield select(settingSelectors.getSettingState);
  const isLoggedIn = yield select(authSelectors.isLoggedIn);
  if (isLoggedIn) {
    const userId = yield select(authSelectors.userId);
    yield call(settingService.saveSetting, userId, workspaceId, JSON.stringify(setting));
    return;
  }

  localStorage.setItem(`${workspaceId}/setting`, JSON.stringify(setting));
}

function* saveProjectSetting(): any {
  const currentProjectId = yield select(projectSelectors.activeProjectId);
  const setting: SettingState = yield select(settingSelectors.getSettingState);
  localStorage.setItem(`${currentProjectId}/setting`, JSON.stringify(setting));
}

function* loadDashboardSetting({ payload: { id } }: any) {
  const setting = localStorage.getItem(`${id}/dashboardSetting`);
  const parsed = ((setting && (JSON.parse(setting) as SettingState['dashboardSetting'])) ||
    {}) as SettingState['dashboardSetting'];
  yield put(dashboardSettingLoaded(parsed));
}

function* saveDashboardSetting({ payload: { id } }: any) {
  const setting: SettingState = yield select(settingSelectors.getSettingState);
  localStorage.setItem(`${id}/dashboardSetting`, JSON.stringify(setting.dashboardSetting));
}

export const settingSaga = [
  takeEvery(LOAD_WORKSPACE_REQUEST, loadSettingSaga),
  takeEvery(CLOSE_SEGMENT_PANEL, saveSetting),
  takeEvery(OPEN_SEGMENT_PANEL, saveSetting),
  takeEvery(CLOSE_RULES_PANEL, saveSetting),
  takeEvery(OPEN_RULES_PANEL, saveSetting),
  takeEvery(CLOSE_TEST_CASE_PANEL, saveSetting),
  takeEvery(OPEN_TEST_CASE_PANEL, saveSetting),
  takeEvery(SYNTAX_HIGHLIGHT_TOGGLE, saveSetting),
  takeEvery(WORD_WRAP_TOGGLE, saveSetting),
  takeEvery(TEST_CASE_WORD_WRAP_TOGGLE, saveSetting),
  takeEvery(AUTO_SUGGEST_TOGGLE, saveSetting),
  takeEvery(TEST_CASE_AUTO_SUGGEST_TOGGLE, saveSetting),
  takeEvery(UPDATE_SPLIT2_SIZE, saveSetting),
  takeEvery(UPDATE_SPLIT1_SIZE, saveSetting),
  takeEvery(UPDATE_SPLIT3_SIZE, saveSetting),
  takeEvery(CLOSE_RESULTS_PANEL, saveSetting),
  takeEvery(OPEN_RESULTS_PANEL, saveSetting),
  takeEvery(TOGGLE_DESCRIPTION, saveSetting),
  takeEvery(TOGGLE_FACTS, saveSetting),
  takeEvery(TOGGLE_REQUIRED_RESULTS, saveSetting),
  takeEvery(TOGGLE_UNWANTED_RESULTS, saveSetting),
  takeEvery(ACTIVATE_TEST_CASE, saveSetting),
  takeEvery(ACTIVATE_TEST_CASE, saveSetting),
  takeEvery(SET_TEST_CASE_SETTING, saveSetting),
  takeEvery(DELETE_TEST_CASE_SETTING, saveSetting),
  takeEvery(SYNC_TEST_CASE_DELETE, saveSetting),
  takeEvery(CLOSE_TEST_CASE_DRAWER, saveSetting),
  takeEvery(SORT_TEST_CASES, saveSetting),
  takeEvery(START_WATCH_MODE, saveSetting),
  takeEvery(COLLAPSE_SEGMENT, saveSetting),
  takeEvery(UNCOLLAPSE_SEGMENT, saveSetting),
  takeEvery(STOP_WATCH_MODE, saveSetting),
  takeEvery(TOGGLE_AUTO_SCROLL, saveSetting),
  takeEvery(CLOSE_PROJECT_DRAWER, saveProjectSetting),
  takeEvery(ADD_PROJECT_INITIATOR, saveProjectSetting),
  takeEvery(HIGHLIGHT_PROJECT_ID, saveProjectSetting),
  takeEvery(MASTER_PROJECT_ID, saveProjectSetting),
  takeEvery(LOAD_DASHBOARD_REQUEST, loadDashboardSetting),
  takeEvery(DASHBOARD_URL_SAVE_MSG, saveDashboardSetting),
];
