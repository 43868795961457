import { createSelector } from 'reselect';

import { SettingState, TestCaseSetting, initTestCaseSetting } from './reducer';

export const getSettingState = (state: any) => state.setting as SettingState;

const syntaxHighlight = createSelector(getSettingState, ({ syntaxHighlight }: SettingState) => syntaxHighlight);

const wordWrap = createSelector(getSettingState, ({ wordWrap }: SettingState) => wordWrap);

const testCaseWordWrap = createSelector(getSettingState, ({ testCaseWordWrap }: SettingState) => testCaseWordWrap);

const autoSuggest = createSelector(getSettingState, ({ autoSuggest }: SettingState) => autoSuggest);

const testCaseAutoSuggest = createSelector(
  getSettingState,
  ({ testCaseAutoSuggest }: SettingState) => testCaseAutoSuggest
);

const autoScroll = createSelector(getSettingState, ({ autoScroll }) => autoScroll);

const isSegmentPanelClosed = createSelector(
  getSettingState,
  ({ segmentPanelClosed }: SettingState) => segmentPanelClosed
);

const isRulePanelClosed = createSelector(getSettingState, ({ rulesPanelClosed }: SettingState) => rulesPanelClosed);

const isTestCasePanelClosed = createSelector(
  getSettingState,
  ({ testCasePanelClosed }: SettingState) => testCasePanelClosed
);

const isResultsPanelClosed = createSelector(
  getSettingState,
  ({ resultsPanelClosed }: SettingState) => resultsPanelClosed
);

const split1Size = createSelector(getSettingState, ({ split1Size }: SettingState) => split1Size);

const split2Size = createSelector(getSettingState, ({ split2Size }: SettingState) => split2Size);

const split3Size = createSelector(getSettingState, ({ split3Size }: SettingState) => split3Size);

const segmentPanelFullScreen = createSelector(
  isSegmentPanelClosed,
  isRulePanelClosed,
  isTestCasePanelClosed,
  (segmentClosed: boolean, ruleClosed: boolean, testClosed: boolean) => !segmentClosed && ruleClosed && testClosed
);

const rulePanelFullScreen = createSelector(
  isSegmentPanelClosed,
  isRulePanelClosed,
  isTestCasePanelClosed,
  (segmentClosed: boolean, ruleClosed: boolean, testClosed: boolean) => segmentClosed && !ruleClosed && testClosed
);

const testPanelFullScreen = createSelector(
  isSegmentPanelClosed,
  isRulePanelClosed,
  isTestCasePanelClosed,
  (segmentClosed: boolean, ruleClosed: boolean, testClosed: boolean) => segmentClosed && ruleClosed && !testClosed
);

const testcaseDrawerClosed = createSelector(getSettingState, ({ testcaseDrawerClosed }) => testcaseDrawerClosed);

const lastAccessedTestCaseId = createSelector(getSettingState, ({ lastAccessedTestCaseId }) => lastAccessedTestCaseId);

const testCaseSettingById = (testCaseId: string) =>
  createSelector(
    getSettingState,
    ({ testCaseSetting }) => testCaseSetting[testCaseId] || ({ ...initTestCaseSetting } as TestCaseSetting)
  );

const isLoading = createSelector(getSettingState, ({ loading }) => loading);

const watchModeOn = createSelector(getSettingState, ({ watchModeOn }) => watchModeOn);

const sortByType = createSelector(getSettingState, ({ sortByType }) => sortByType);

const projectDrawerClosed = createSelector(getSettingState, ({ projectDrawerClosed }) => projectDrawerClosed);

const addProjectInitiator = createSelector(getSettingState, ({ addProjectInitiator }) => addProjectInitiator);

const importProjectTarget = createSelector(getSettingState, ({ importProjectTarget }) => importProjectTarget);

const projectIdToHighlight = createSelector(getSettingState, ({ projectIdToHighlight }) => projectIdToHighlight);

const masterProjectId = createSelector(getSettingState, ({ masterProjectId }) => masterProjectId);

const isWatchModeOn = createSelector(getSettingState, ({ watchModeOn }) => watchModeOn);

const saveUrlMessageDisplayed = createSelector(
  getSettingState,
  ({ dashboardSetting }) => dashboardSetting.saveUrlMessageDisplayed
);

const collapsedSegment = createSelector(getSettingState, ({ collapsedSegments }) => collapsedSegments);

export const settingSelectors = {
  getSettingState,
  isSegmentPanelClosed,
  isTestCasePanelClosed,
  isRulePanelClosed,
  syntaxHighlight,
  wordWrap,
  testCaseWordWrap,
  autoSuggest,
  testCaseAutoSuggest,
  segmentPanelFullScreen,
  rulePanelFullScreen,
  testPanelFullScreen,
  split1Size,
  split2Size,
  split3Size,
  isResultsPanelClosed,
  testcaseDrawerClosed,
  testCaseSettingById,
  lastAccessedTestCaseId,
  isLoading,
  watchModeOn,
  sortByType,
  autoScroll,
  isWatchModeOn,
  projectDrawerClosed,
  addProjectInitiator,
  importProjectTarget,
  projectIdToHighlight,
  masterProjectId,
  saveUrlMessageDisplayed,
  collapsedSegment,
};
