import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient();

export const queryKey = {
  atoms: 'atoms',
  codeGenDebug: 'codeGenDebug',
  tags: 'tags',
  references: 'references',
} as const;
