import { ResultsLineStatus, SortByType, TestCase, TestCaseStatus } from './reducer';

export const SAVE_TITLE = 'testCase/SAVE_TITLE';
export const SAVE_FACTS = 'testCase/SAVE_FACTS';
export const SAVE_REQUIRED_RESULTS = 'testCase/SAVE_REQUIRED_RESULTS';
export const SAVE_UNWANTED_RESULTS = 'testCase/SAVE_UNWANTED_RESULTS';
export const SAVE_DESCRIPTION = 'testCase/SAVE_DESCRIPTION';
export const RUN_TEST_CASE = 'testCase/RUN_TEST_CASE';
export const UPDATE_RESULTS = 'testCase/UPDATE_RESULTS';
export const CREATE_NEW_TEST_CASE = 'testCase/CREATE_NEW_TEST_CASE';
export const CREATE_NEW_TEST_CASE_OPTIMISTIC = 'testCase/CREATE_NEW_TEST_CASE_OPTIMISTIC';
export const CREATE_NEW_TEST_CASE_SUCCESS = 'testCase/CREATE_NEW_TEST_CASE_SUCCESS';
export const COPY_TEST_CASE = 'testCase/COPY_TEST_CASE';
export const COPY_TEST_CASE_OPTIMISTIC = 'testCase/COPY_TEST_CASE_OPTIMISTIC';
export const COPY_TEST_CASE_SUCCESS = 'testCase/COPY_TEST_CASE_SUCCESS';
export const ACTIVATE_TEST_CASE = 'testCase/ACTIVATE_TEST_CASE';
export const START_WATCH_MODE = 'testCase/START_WATCH_MODE';
export const STOP_WATCH_MODE = 'testCase/STOP_WATCH_MODE';
export const RERUN_TEST_CASES = 'testCase/RERUN_TEST_CASES';
export const DELETE_TEST_CASE = 'testCase/DELETE_TEST_CASE';
export const DELETE_TEST_CASE_OPTIMISTIC = 'testCase/DELETE_TEST_CASE_OPTIMISTIC';
export const DELETE_TEST_CASE_SUCCESS = 'testCase/DELETE_TEST_CASE_SUCCESS';
export const CLONE_TEST_CASE = 'testCase/CLONE_TEST_CASE';
export const CLONE_TEST_CASE_SUCCESS = 'testCase/CLONE_TEST_CASE_SUCCESS';
export const CLONE_TEST_CASE_OPTIMISTIC = 'testCase/CLONE_TEST_CASE_OPTIMISTIC';
export const LOAD_ALL_TESTCASES = 'testCase/LOAD_ALL_TESTCASES';
export const LOAD_ALL_TESTCASES_OPTIMISTIC = 'testCase/LOAD_ALL_TESTCASES_OPTIMISTIC';
export const PROCESS_TEST_CASE_UPDATE_SUBSCRIPTION = 'testCase/PROCESS_TEST_CASE_UPDATE_SUBSCRIPTION';
export const PROCESS_TEST_CASE_DELETE_SUBSCRIPTION = 'testCase/PROCESS_TEST_CASE_DELETE_SUBSCRIPTION';

export const SYNC_TEST_CASE_UPDATE = 'testCase/SYNC_TEST_CASE_UPDATE';
export const SYNC_TEST_CASE_DELETE = 'testCase/SYNC_TEST_CASE_DELETE';
export const SORT_TEST_CASES = 'testCase/SORT_TEST_CASES';
export const UPDATE_STATUS = 'testCase/UPDATE_STATUS';
export const TEST_CASE_PANEL_LOADED = 'testCase/TEST_CASE_PANEL_LOADED';
export const API_ERROR = 'testCase/API_ERROR';

export const saveFacts = (facts: string) => ({
  type: SAVE_FACTS,
  payload: {
    facts,
  },
});

export const saveTitle = (title: string) => ({
  type: SAVE_TITLE,
  payload: {
    title,
  },
});

export const saveDescription = (description: string) => ({
  type: SAVE_DESCRIPTION,
  payload: {
    description,
  },
});

export const saveRequiredResults = (requiredResults: string) => ({
  type: SAVE_REQUIRED_RESULTS,
  payload: {
    requiredResults,
  },
});

export const saveUnwantedResults = (unwantedResults: string) => ({
  type: SAVE_UNWANTED_RESULTS,
  payload: {
    unwantedResults,
  },
});

export const runTestCase = (testCaseId: string) => ({
  type: RUN_TEST_CASE,
  payload: {
    testCaseId,
  },
});

export const updateResults = (
  testCaseId: string,
  results: any,
  status: TestCaseStatus,
  requiredResultsStatus: ResultsLineStatus[],
  unwantedResultsStatus: ResultsLineStatus[],
  resultsLineStatus: ResultsLineStatus[]
) => ({
  type: UPDATE_RESULTS,
  payload: {
    testCaseId,
    results,
    status,
    requiredResultsStatus,
    unwantedResultsStatus,
    resultsLineStatus,
  },
});

export const activateTestCase = (id: string) => ({
  type: ACTIVATE_TEST_CASE,
  payload: {
    id,
  },
});

export const startWatchMode = () => ({
  type: START_WATCH_MODE,
});

export const stopWatchMode = () => ({
  type: STOP_WATCH_MODE,
});

export const deleteTestCase = (testCaseId: string) => ({
  type: DELETE_TEST_CASE,
  payload: {
    testCaseId,
  },
});

export const deleteTestCaseSuccess = (id: string) => ({
  type: DELETE_TEST_CASE_SUCCESS,
  payload: {
    id,
  },
});

export const deleteTestCaseOptimistic = (testCaseId: string) => ({
  type: DELETE_TEST_CASE_OPTIMISTIC,
  payload: {
    testCaseId,
  },
});

export const cloneTestCase = () => ({
  type: CLONE_TEST_CASE,
});

export const cloneTestCaseOptimistic = (testCase: TestCase) => ({
  type: CLONE_TEST_CASE_OPTIMISTIC,
  payload: {
    testCase,
  },
});

export const cloneTestCaseSuccess = (testCase: TestCase) => ({
  type: CLONE_TEST_CASE_SUCCESS,
  payload: {
    testCase,
  },
});

export const createNewTestCase = () => ({
  type: CREATE_NEW_TEST_CASE,
});

export const createNewTestCaseOptimistic = (testCase: TestCase) => ({
  type: CREATE_NEW_TEST_CASE_OPTIMISTIC,
  payload: {
    testCase,
  },
});

export const createNewTestCaseSuccess = (testCase: TestCase) => ({
  type: CREATE_NEW_TEST_CASE_SUCCESS,
  payload: {
    testCase,
  },
});

export const copyTestCase = (testcaseId: string, projectId: string, workspaceId: string) => ({
  type: COPY_TEST_CASE,
  payload: {
    testcaseId,
    projectId,
    workspaceId,
  },
});

export const copyTestCaseOptimistic = (testCase: TestCase) => ({
  type: COPY_TEST_CASE_OPTIMISTIC,
  payload: {
    testCase,
  },
});

export const copyTestCaseSuccess = (testCase: TestCase) => ({
  type: COPY_TEST_CASE_SUCCESS,
  payload: {
    testCase,
  },
});

export const ProcessTestCaseUpdate = (testCase: TestCase) => ({
  type: PROCESS_TEST_CASE_UPDATE_SUBSCRIPTION,
  payload: {
    testCase,
  },
});
export const ProcessTestCaseDelete = (testCase: TestCase) => ({
  type: PROCESS_TEST_CASE_DELETE_SUBSCRIPTION,
  payload: {
    testCase,
  },
});

export const syncTestCaseUpdate = (testCase: TestCase) => ({
  type: SYNC_TEST_CASE_UPDATE,
  payload: {
    testCase,
  },
});

export const syncTestCaseDelete = (testCaseId: string) => ({
  type: SYNC_TEST_CASE_DELETE,
  payload: {
    testCaseId,
  },
});

export const sortTestCases = (sortByType: SortByType) => ({
  type: SORT_TEST_CASES,
  payload: {
    sortByType,
  },
});

export const updateStatus = (testCaseId: string, status: TestCaseStatus) => ({
  type: UPDATE_STATUS,
  payload: {
    testCaseId,
    status,
  },
});

export const rerunTestCases = () => ({
  type: RERUN_TEST_CASES,
});

export const testCasePanelLoaded = () => ({
  type: TEST_CASE_PANEL_LOADED,
});

export const loadAllTestcases = () => ({
  type: LOAD_ALL_TESTCASES,
});

export const loadAllTestcasesOptimistic = (testCases: TestCase[]) => ({
  type: LOAD_ALL_TESTCASES_OPTIMISTIC,
  payload: {
    testCases,
  },
});
export const apiError = (error: any) => ({
  type: API_ERROR,
  payload: {
    error,
  },
});
