import * as _ from 'lodash';
import { createSelector } from 'reselect';

import { util } from '../../services';
import { settingSelectors } from '../setting';
import { workspaceSelectors } from '../workspace';
import { Segment, SegmentState } from './reducer';

const toNum = util.toNum;

const getSegmentState = (state: any) => state.segment as SegmentState;

const isLoading = createSelector(getSegmentState, ({ loading }) => loading);

const allSegments = createSelector(
  getSegmentState,
  workspaceSelectors.currentId,
  settingSelectors.collapsedSegment,
  workspaceSelectors.isLoading,
  workspaceSelectors.isLoading,
  (
    { segmentsMap, loading }: SegmentState,
    workspaceId: string,
    collapsedSegments: Segment[],
    isLoading: boolean,
    isWorkspaceLoading: boolean
  ) => {
    if (isLoading || isWorkspaceLoading || loading) {
      return [];
    }
    const segments = Object.keys(segmentsMap)
      .filter((id) => segmentsMap[id].workspaceId === workspaceId)
      .filter((id) => segmentsMap[id].type === 'selected')
      .map((id) => segmentsMap[id]);

    const filteredCollapsed = collapsedSegments.filter((segment) => {
      const collapsedStartId = toNum(segment.startLegalTextId);
      const collapsedEndId = toNum(segment.endLegalTextId);

      const clashedSegment = segments.find((selectedSegment) => {
        const selectedStartId = toNum(selectedSegment.startLegalTextId);
        const selectedEndId = toNum(selectedSegment.endLegalTextId);
        if (_.inRange(selectedStartId, collapsedStartId, collapsedEndId)) {
          return true;
        }
        if (_.inRange(selectedEndId, collapsedStartId, collapsedEndId)) {
          return true;
        }
        return false;
      });
      return clashedSegment ? false : true;
    });

    const allSegments = [...segments, ...filteredCollapsed];

    return allSegments.sort((a: Segment, b: Segment) => toNum(a.startLegalTextId) - toNum(b.startLegalTextId));
  }
);

const allSelectedSegmentsByWorkspaceId = (workspaceId: string) => {
  return createSelector(getSegmentState, ({ segmentsMap }: SegmentState) => {
    return Object.keys(segmentsMap)
      .filter((id) => segmentsMap[id].workspaceId === workspaceId)
      .filter((id) => segmentsMap[id].type === 'selected')
      .map((id) => segmentsMap[id])
      .sort((a: Segment, b: Segment) => toNum(a.startLegalTextId) - toNum(b.startLegalTextId));
  });
};

const allSegmentsByWorkspaceId = (workspaceId: string) => {
  return createSelector(getSegmentState, ({ segmentsMap }: SegmentState) => {
    return Object.keys(segmentsMap)
      .filter((id) => segmentsMap[id].workspaceId === workspaceId)
      .map((id) => segmentsMap[id])
      .sort((a: Segment, b: Segment) => toNum(a.startLegalTextId) - toNum(b.startLegalTextId));
  });
};

const selectedSegments = createSelector(allSegments, (segments: Segment[]) =>
  segments.filter((s) => s.type === 'selected')
);

const selectedSegmentsByWorkspaceIds = (workspaceIds: string[]) => {
  let segments: any = [];
  workspaceIds.forEach((workspaceId) => {
    segments = allSelectedSegmentsByWorkspaceId(workspaceId);
  });
  return segments;
};

const lastSelectedSegment = createSelector(
  selectedSegments,
  (segments: Segment[]) => (segments.length && segments[segments.length - 1]) || null
);

const firstSelectedSegmentId = createSelector(
  selectedSegments,
  (segments: Segment[]) => segments.length && segments[0].id
);

const activeSegmentId = createSelector(getSegmentState, ({ activeSegmentId }: SegmentState) => activeSegmentId);

const resizingSegmentId = createSelector(getSegmentState, ({ resizingSegmentId }: SegmentState) => resizingSegmentId);

const lastLegalTextId = createSelector(getSegmentState, ({ lastLegalTextId }: SegmentState) => lastLegalTextId);

const hoveredSegmentId = createSelector(getSegmentState, ({ hoveredSegmentId }) => hoveredSegmentId);

const activeSegment = createSelector(
  getSegmentState,
  ({ activeSegmentId, segmentsMap }: SegmentState) => segmentsMap[activeSegmentId]
);

const segmentById = (id: string) => {
  return createSelector(getSegmentState, ({ segmentsMap }: SegmentState) => segmentsMap[id]);
};

const hasRules = createSelector(
  getSegmentState,
  workspaceSelectors.currentId,
  ({ segmentsMap }: SegmentState, workspaceId: string) => {
    return (
      Object.keys(segmentsMap).filter((id) => segmentsMap[id].workspaceId === workspaceId && segmentsMap[id].rule)
        .length > 0
    );
  }
);

const generateAllSegmentTypes = createSelector(
  allSegments,
  workspaceSelectors.currentLegalTextCount,
  (segments: Segment[], legalTextCount: number) =>
    segments.reduce((accumulator, segment, index) => {
      if (index === 0) {
        const topUnselectedSegment = createTopUnselectedSegment(segment);
        topUnselectedSegment && accumulator.push(topUnselectedSegment);
      }

      accumulator.push(segment);

      const bottomUnselectedSegment = createBottomUnselectedSegment(segment, segments[index + 1], legalTextCount);
      if (bottomUnselectedSegment) {
        accumulator.push(bottomUnselectedSegment);
      }

      return accumulator;
    }, [] as any)
);

const createTopUnselectedSegment = ({ workspaceId, startLegalTextId }: Segment): Segment | undefined => {
  const startId = toNum(startLegalTextId);

  if (startId === 1) {
    return;
  }

  return createUnselectedSegment(1, startId - 1, workspaceId);
};

const createBottomUnselectedSegment = (
  segment: Segment,
  nextSegment: Segment,
  legalTextCount: number
): Segment | undefined => {
  const { workspaceId, endLegalTextId } = segment;
  const endId = toNum(endLegalTextId);
  const nextStartId = nextSegment && toNum(nextSegment.startLegalTextId);

  if (endId === legalTextCount || endId + 1 === nextStartId || endId >= nextStartId) {
    return;
  }

  if (nextSegment) {
    return createUnselectedSegment(endId + 1, nextStartId - 1, workspaceId);
  } else {
    return createUnselectedSegment(endId + 1, legalTextCount, workspaceId);
  }
};

const createUnselectedSegment = (startId: number, endId: number, workspaceId: string) =>
  ({
    id: `unselected-${startId}-${endId}`,
    workspaceId,
    type: 'unselected',
    startLegalTextId: 'lt-' + startId,
    endLegalTextId: 'lt-' + endId,
    version: 1,
  }) as Segment;

const unselectedSegments = createSelector(generateAllSegmentTypes, (allSegments: Segment[]) =>
  allSegments.filter((segment) => segment.type === 'unselected')
);

const collapsedSegments = createSelector(allSegments, (segments: Segment[]) =>
  segments.filter((s) => s.type === 'collapsed')
);

const collapsedLegalTextIds = createSelector(collapsedSegments, (segments: Segment[]) =>
  segments.reduce((accumulator, { type, startLegalTextId, endLegalTextId }) => {
    if (type === 'collapsed') {
      const ids = util.getIdsInBetween(startLegalTextId, endLegalTextId);
      return [...accumulator, ...ids];
    }
    return accumulator;
  }, [] as any)
);

const selectedLegalTextIds = createSelector(selectedSegments, (segments: Segment[]) =>
  segments.reduce((accumulator, { type, startLegalTextId, endLegalTextId }) => {
    if (type === 'selected') {
      const ids = util.getIdsInBetween(startLegalTextId, endLegalTextId);
      return [...accumulator, ...ids];
    }
    return accumulator;
  }, [] as any)
);

const placeholderLegalTextIds = createSelector(collapsedSegments, (segments: Segment[]) =>
  segments.reduce((accumulator, { type, startLegalTextId }) => {
    if (type === 'collapsed') {
      accumulator.push(startLegalTextId);
    }
    return accumulator;
  }, [] as any)
);

const masterAtomList = createSelector(getSegmentState, ({ masterAtomList }: SegmentState) => masterAtomList);

const isLegalTextSelected = (legalTextId: string) =>
  createSelector(selectedLegalTextIds, (ids: string[]) => ids.includes(legalTextId));

const segmentIdByLegalTextId = (legalTextId: string) =>
  createSelector(selectedSegments, (segments: Segment[]) => {
    const segment = segments.find((segment: Segment) =>
      util.getIdsInBetween(segment.startLegalTextId, segment.endLegalTextId).includes(legalTextId)
    );
    return (segment && segment.id) || '';
  });

export const segmentSelectors = {
  allSegments,
  selectedSegments,
  selectedSegmentsByWorkspaceIds,
  allSelectedSegmentsByWorkspaceId,
  allSegmentsByWorkspaceId,
  generateAllSegmentTypes,
  activeSegmentId,
  activeSegment,
  segmentById,
  hasRules,
  collapsedSegments,
  collapsedLegalTextIds,
  selectedLegalTextIds,
  unselectedSegments,
  placeholderLegalTextIds,
  lastSelectedSegment,
  firstSelectedSegmentId,
  masterAtomList,
  isLegalTextSelected,
  segmentIdByLegalTextId,
  hoveredSegmentId,
  lastLegalTextId,
  resizingSegmentId,
  isLoading,
};
