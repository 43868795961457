import { navigate } from '@gatsbyjs/reach-router';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import * as _ from 'lodash';
import { call, debounce, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { projectService as service, testCaseService, util, workspaceService } from '../../services';
import { dashboardService } from '../../services';
import { dashboardSelector } from '../dashboard';
import { addProject, addProjectLocal } from '../dashboard';
import { Segment } from '../segment';
import { ProcessSegmentDelete, ProcessSegmentUpdate, createNewSegment, segmentSelectors } from '../segment';
import { ProcessTestCaseDelete, ProcessTestCaseUpdate, copyTestCase, testCaseSelectors } from '../testCase';
import { TestCase } from '../testCase';
import { updateWorkspaceDesc, updateWorkspaceName, workspaceAdded } from '../workspace';
import { Workspace } from '../workspace';
import {
  ADD_PROJECT_REFERENCE,
  COPY_PROJECT,
  CREATE_DOCUMENT,
  CREATE_PROJECT_REQUEST,
  DELETE_PROJECT,
  EXPORT_PROJECT,
  EXPORT_PROJECTS_RULES,
  LINK_PROJECT,
  LOAD_ALL_PROJECTS,
  LOAD_ALL_SUB_PROJECTS,
  LOAD_PROJECT_REQUEST,
  PROCESS_UPDATE_NESTED_PARENT,
  REFRESH_PROJECT,
  REMOVE_DOCUMENT,
  REMOVE_PROJECT,
  REMOVE_PROJECT_REFERENCE,
  RESTORE_DOCUMENT,
  RESTORE_PROJECT,
  SUBSCRIBE_PROJECT_UPDATES,
  UNSUBSCRIBE_PROJECT_UPDATES,
  UPDATE_DOCUMENT_DESC,
  UPDATE_DOCUMENT_NAME,
  UPDATE_PROJECT_NAME,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_ROOT_PROJECT_ID,
  addProjectRefOptimistic,
  allProjectsLoaded,
  allSubProjectsLoaded,
  apiError,
  copyProjectOptimistic,
  createProjectOptimistic,
  deleteProject,
  deleteProjectOptimistic,
  documentDescUpdated,
  documentNameUpdated,
  linkProjectOptimistic,
  linkProjectSuccess,
  loadProject,
  projectLoaded,
  projectNameUpdated,
  projectRefAdded,
  projectRefRemoved,
  projectRefreshed,
  removeDocumentOptimistic,
  removeDocumentSuccess,
  removeProjectOptimistic,
  removeProjectRefOptimistic,
  restoreDocumentOptimistic,
  restoreDocumentSuccess,
  restoreProjectOptimistic,
  syncProjectRemove,
  syncProjectUpdate,
  updateDocumentDescOptimistic,
  updateDocumentNameOptimistic,
  updateNestedParents,
  updateRootProjectIdOptimistic,
  updateRootProjectIdSuccess,
} from './actions';
import { Project, RuleSuite } from './reducer';
import { projectSelectors } from './selectors';

const filenamify = require('filenamify');

const timestamp = require('time-stamp');
const ObjectID = require('bson-objectid');
const workspaceToCreate = new Map<string, string>();
const formatVersion = 1;

function* createProjectRequestSaga({ payload }: any) {
  const { dashboardId, name, email } = payload;
  const uniqueId = ObjectID().toString();

  try {
    const newProject = {
      id: uniqueId,
      name,
      isLinked: false,
      linkCount: 0,
      ruleSuites: [],
      rootProjectId: uniqueId,
      createdDate: new Date(),
      createdBy: email,
      removedAt: null,
      access: {},
    } as Project;

    yield put(createProjectOptimistic(newProject));
    if (dashboardId) {
      navigate(`/dashboard/${dashboardId}`);
    }

    if (dashboardId) {
      yield put(addProject(dashboardId, newProject.id));
    } else {
      yield put(addProjectLocal(email, newProject.id));
    }

    yield call(service.createProject, newProject);
    if (!dashboardId) {
      // This is only for logged in users.
      // Nitish has a diferent flow for dashboardId users
      yield put(loadProject(newProject.id));
    }
  } catch (e) {
    console.error(e);
    yield put(apiError(e));
  }
}

function* loadProjectRequest({ payload }: any): any {
  const { projectId } = payload;
  try {
    const project: any = yield call(service.getProject, projectId);
    yield put(projectLoaded(project));
  } catch (e) {
    console.error(e);
    yield put(apiError(e));
  }
}

function* refreshProjectSaga({ payload }: any) {
  if (payload.id === undefined) return;
  const project: Project = yield call(service.getProject, payload.id);
  yield put(projectRefreshed(project, ''));
}

function* updateProjectNameSaga({ payload }: any): any {
  const { projectId } = payload;
  const project = yield select(projectSelectors.projectById(projectId));
  const updatedProject: Project = yield call(service.updateProject, project);
  yield put(projectNameUpdated(updatedProject));
}

// eslint-disable-next-line
function* updateProjectNameSuccessSaga() {}

function* addProjectRefSaga({ payload }: any): any {
  try {
    const { dashboardId, refProjectId, currentProjectId } = payload;
    yield put(addProjectRefOptimistic(refProjectId, currentProjectId));
    const refProject = yield select(projectSelectors.projectById(refProjectId));
    yield call(service.updateProject, refProject);
    const project: Project = yield call(service.addProjectReference, refProjectId, currentProjectId);
    const dashboards: string[] = yield call(dashboardService.dashboardsByProjectId, currentProjectId);
    let dashboardList = Object.values(dashboards);
    dashboardList = dashboardList.filter((id) => id !== dashboardId);
    for (let i = 0; i < dashboardList.length; i++) {
      const dashboardId: string = dashboardList[i];
      yield call(dashboardService.addProject, dashboardId, refProjectId);
    }
    yield put(projectRefAdded(project));
  } catch (e) {
    console.error(e);
    yield put(apiError(e));
  }
}

function* removeProjectRefSaga({ payload }: any): any {
  try {
    const { refProjectId, currentProjectId } = payload;
    yield put(removeProjectRefOptimistic(refProjectId, currentProjectId));
    const refProject = yield select(projectSelectors.projectById(refProjectId));
    yield call(service.updateProject, refProject);
    const project: Project = yield call(service.removeProjectReference, refProjectId, currentProjectId);
    yield put(projectRefRemoved(project));
  } catch (e) {
    console.error(e);
    yield put(apiError(e));
  }
}

function* loadAllProjectsSaga({ payload }: any) {
  const { dashboardId } = payload;
  const allProjects: any = [];
  const projectIds: string[] = yield select(dashboardSelector.projects(dashboardId));
  for (const id of projectIds) {
    const project: Project = yield call(service.getProject, id);
    allProjects.push(project);
  }
  yield put(allProjectsLoaded(allProjects));
}

function* createDocumentSaga({ payload }: any) {
  const { activeDashboardId, projectId } = payload;
  navigate(`/dashboard/${activeDashboardId}/project/${projectId}`);
}
function* copyProjectSaga({ payload }: any): any {
  const { projectId } = payload;
  const currentProject: Project = yield select(projectSelectors.projectById(projectId));
  workspaceToCreate.clear();
  const newRuleSuites: RuleSuite[] = buildRulesuite(currentProject);
  const newProject: Project = buildNewProject(currentProject, newRuleSuites, '-copy');
  yield put(copyProjectOptimistic(newProject));
  for (const key of Array.from(workspaceToCreate.keys())) {
    const allSegments: Segment[] = yield select(segmentSelectors.allSegmentsByWorkspaceId(key));
    for (let i = 0; i < allSegments.length; i++) {
      const segment: Segment = allSegments[i];
      yield put(
        createNewSegment(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          workspaceToCreate.get(key)!,
          segment.startLegalTextId,
          segment.endLegalTextId,
          segment.type,
          segment.rule
        )
      );
    }
  }
  for (const key of Array.from(workspaceToCreate.keys())) {
    const workspaceById = yield call(workspaceService.getWorkspace, key);
    const newWorkspace = buildNewWorkspace(workspaceById, key);
    const { id, convertedHTML, legalTextCount, name, desc, migrated, urlPath } = yield call(
      workspaceService.createWorkspaceForProject,
      newWorkspace
    );
    yield put(workspaceAdded(id, convertedHTML, legalTextCount, name, desc, null, migrated, urlPath));
  }
  // @ts-ignore
  const allTestcases: TestCase[] = yield select(testCaseSelectors.allSavedTestCasesForProject(projectId));
  for (let i = 0; i < allTestcases.length; i++) {
    const testcase: TestCase = allTestcases[i];

    const newWorkspaceId: any = workspaceToCreate.has(testcase.workspaceId.valueOf())
      ? workspaceToCreate.get(testcase.workspaceId.valueOf())
      : testcase.workspaceId;

    yield put(copyTestCase(testcase.id, newProject.id, newWorkspaceId));
  }

  const activeDashboardId = yield select(dashboardSelector.activeDashboardId);
  const userId = yield select(dashboardSelector.userDashboardId);

  if (!activeDashboardId) {
    newProject.createdBy = userId;
  }

  const project: Project = yield call(service.createProject, newProject);

  if (activeDashboardId) {
    yield put(addProject(activeDashboardId, project.id));
    navigate(`/dashboard/${activeDashboardId}`);
  } else {
    yield put(addProjectLocal(userId, project.id));
    yield put(projectLoaded(project));
  }
}

const buildRulesuite = (project: Project) => {
  const ruleSuites: RuleSuite[] = [];
  let counter = 1;
  project.ruleSuites.map((ruleSuite) => {
    if (ruleSuite.type === 'workspace') {
      const uniqueId = ObjectID().toString();
      workspaceToCreate.set(ruleSuite.id, uniqueId);
      ruleSuites.push({
        id: uniqueId,
        type: 'workspace',
        seqNo: counter++,
        isLinked: ruleSuite.isLinked,
        name: ruleSuite.name,
        desc: ruleSuite.desc,
        removedAt: null,
      });
    } else if (ruleSuite.type === 'project') {
      ruleSuites.push({
        id: ruleSuite.id,
        type: 'project',
        seqNo: counter++,
        isLinked: ruleSuite.isLinked,
        removedAt: null,
      });
    }
  });
  return ruleSuites;
};

const buildNewProject = (currentProject: Project, newRuleSuites: RuleSuite[], tag: string): Project => {
  const uniqueId = ObjectID().toString();
  return {
    id: uniqueId,
    name: currentProject.name ? currentProject.name.trim().concat(tag) : 'Untitled Project' + tag,
    ruleSuites: newRuleSuites,
    isLinked: currentProject.isLinked,
    linkCount: 0,
    rootProjectId: uniqueId,
    createdDate: new Date(),
    removedAt: null,
    access: null,
  };
};

const buildNewWorkspace = (workspaceById: Workspace, key: string): Workspace => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    id: workspaceToCreate.get(key)!,
    legalTextCount: workspaceById.legalTextCount,
    convertedHTML: workspaceById.convertedHTML,
    name: workspaceById.name,
    desc: workspaceById.desc,
    migrated: workspaceById.migrated,
    urlPath: workspaceById.urlPath,
  };
};

function* deleteProjectSaga({ payload }: any) {
  const { projectId } = payload;
  yield put(deleteProjectOptimistic(projectId));
  yield put(deleteProject(projectId));
}

function* removeProjectSaga({ payload }: any): any {
  const { containerId, projectId } = payload;
  yield put(removeProjectOptimistic(containerId, projectId));
  const project: Project = yield call(service.removeProject, containerId, projectId);
  const activeDashboardId = yield select(dashboardSelector.activeDashboardId);
  if (activeDashboardId) {
    navigate(`/dashboard/${activeDashboardId}`);
  } else {
    yield put(projectLoaded(project));
  }
}

function* restoreProjectSaga({ payload }: any) {
  const { projectId, containerId } = payload;
  yield put(restoreProjectOptimistic(projectId, containerId));
  const project: Project = yield call(service.restoreProject, containerId, projectId);
  console.log('RESTORED project:', project);
}

function* removeDocumentSaga({ payload }: any) {
  try {
    const { workspaceId, projectId } = payload;
    yield put(removeDocumentOptimistic(workspaceId, projectId));
    const project: Project = yield call(service.removeDocument, workspaceId, projectId);
    yield put(removeDocumentSuccess(project));
  } catch (e) {
    console.error(e);
    yield put(apiError(e));
  }
}

function* restoreDocumentSaga({ payload }: any) {
  try {
    const { workspaceId, projectId } = payload;
    yield put(restoreDocumentOptimistic(workspaceId, projectId));
    const project: Project = yield call(service.restoreDocument, workspaceId, projectId);
    yield put(restoreDocumentSuccess(project));
  } catch (e) {
    console.error(e);
    yield put(apiError(e));
  }
}

function* updateDocDesSaga({ payload }: any): any {
  const { projectId, documentId, documentDesc } = payload;
  yield put(updateDocumentDescOptimistic(projectId, documentId, documentDesc));
  const project = yield select(projectSelectors.projectById(projectId));
  const updatedProject: Project = yield call(service.updateProject, project);
  yield put(updateWorkspaceDesc(documentId, documentDesc));
  yield put(documentDescUpdated(updatedProject));
}

function* updateDocNameSaga({ payload }: any): any {
  const { projectId, documentId, documentName } = payload;
  yield put(updateDocumentNameOptimistic(projectId, documentId, documentName));
  const project = yield select(projectSelectors.projectById(projectId));
  const updatedProject: Project = yield call(service.updateProject, project);
  yield put(updateWorkspaceName(documentId, documentName));
  yield put(documentNameUpdated(updatedProject));
}

function* updateRootProjectIdSaga({ payload }: any): any {
  const { projectId, rootProjectId } = payload;
  yield put(updateRootProjectIdOptimistic(projectId, rootProjectId));
  const project = yield select(projectSelectors.projectById(projectId));
  project.rootProjectId = rootProjectId;
  const updatedProject: Project = yield call(service.updateProject, project);
  yield put(updateRootProjectIdSuccess(updatedProject));
}

function* subscribeProjectUpdateSaga({ payload }: any): any {
  const { workspaceId, projectId, projectTreeRootId } = payload;
  const apolloChannel = yield call(service.subscribeProjectUpdates, workspaceId, projectId, projectTreeRootId);
  while (true) {
    const data = yield take(apolloChannel);
    const { project, segment } = data;
    if (data.type === 'PROJECT_UPSERT') {
      yield put(syncProjectUpdate(project));
    } else if (data.type === 'PROJECT_REMOVE') {
      const project = yield select(projectSelectors.projectById(data.project.id));
      if (project) {
        yield put(syncProjectRemove(project.id));
      }
    } else if (data.type === 'SEGMENT_UPSERT') {
      yield put(ProcessSegmentUpdate(segment));
    } else if (data.type === 'SEGMENT_DELETE') {
      yield put(ProcessSegmentDelete(segment));
    } else if (data.type === 'DOCUMENT_REMOVE') {
      yield put(syncProjectUpdate(project));
    } else if (data.type === 'DOCUMENT_ADD') {
      yield put(syncProjectUpdate(project));
    } else if (data.type === 'TEST_UPSERT') {
      yield put(ProcessTestCaseUpdate(data.testCase));
    } else if (data.type === 'TEST_DELETE') {
      yield put(ProcessTestCaseDelete(data.testCase));
    }
  }
}

function* unsubscribeProjectUpdateSaga() {
  yield call(service.unsubscribeProjectUpdates);
}

function* linkProjectSaga({ payload }: any): any {
  const { projectId } = payload;
  yield put(linkProjectOptimistic(projectId));
  const project = yield select(projectSelectors.projectById(projectId));
  const updatedProject: Project = yield call(service.updateProject, project);
  yield put(linkProjectSuccess(updatedProject));
}

function* exportProjectSaga({ payload }: any) {
  const { projectId } = payload;
  const rootProject: Project = yield select(projectSelectors.projectById(projectId));
  // rootProject.isLinked = false;
  const zip = new JSZip();
  yield prepareRootMetaData(projectId, formatVersion, zip);
  yield scaffoldProjectHierarchy(rootProject, zip);
  zip
    .generateAsync({
      type: 'blob',
    })
    .then(function (content: any) {
      saveAs(content, filenamify(rootProject.name) + '-' + timestamp('YYYYMMDD:HHmmss') + '.zip');
    });
}

function* prepareRootMetaData(projectId: string, formatVersion: number, zip: any) {
  const metaData: any = {};
  metaData.rootProjectId = projectId;
  metaData.formatVersion = formatVersion;
  zip.file('metadata.json', JSON.stringify(metaData));
}

function* scaffoldProjectHierarchy(project: Project, zip: any): any {
  const rootProjectFolder: any = zip.folder(project.id);
  rootProjectFolder.file('metadata.json', JSON.stringify(project));
  yield attachRootProjectTestcases(project, rootProjectFolder);

  for (let i = 0; i < project.ruleSuites.length; i++) {
    if (project.ruleSuites[i].type === 'workspace') {
      const workspace: Workspace = yield call(workspaceService.getWorkspace, project.ruleSuites[i].id);
      const segments: Segment[] = yield call(workspaceService.getAllSegmentsByWorkspaceId, project.ruleSuites[i].id);
      const trimmedWorkspace = util.trimWorkspaceAttributes(workspace);

      rootProjectFolder.file(workspace.id + '.json', JSON.stringify(trimmedWorkspace));

      rootProjectFolder.file(workspace.id + '-rules.json', JSON.stringify(segments));
    } else if (project.ruleSuites[i].type === 'project') {
      const subProject: Project = yield select(projectSelectors.projectById(project.ruleSuites[i].id));
      rootProjectFolder.folder(subProject.id);
      yield scaffoldProjectHierarchy(subProject, zip);
    }
  }
  return rootProjectFolder;
}

function* attachRootProjectTestcases(rootProject: Project, rootProjectFolder: any): any {
  const testCases: TestCase[] = yield call(testCaseService.getAllTestcases, rootProject.id);
  rootProjectFolder.file('tests.json', JSON.stringify(testCases));
  return rootProjectFolder;
}

function* loadAllSubProjectsSaga({ payload }: any): any {
  const { rootProjectId } = payload;
  const allProjects: any = [];
  const projectIds: any = yield call(service.getAllNestedSubProjectIds, rootProjectId);

  for (const id of _.values(projectIds)) {
    const project: Project = yield call(service.getProject, id);
    allProjects.push(project);
  }
  yield put(allSubProjectsLoaded(allProjects));
}

function* processUpdateNestedParentSaga({ payload }: any): any {
  const { projectIds } = payload;
  const projects: any[] = [];
  for (let i = 0; i < projectIds.length; i++) {
    const project = yield select(projectSelectors.projectById(projectIds[i]));
    projects.push(project);
  }
  yield put(updateNestedParents(projects));
}

function* exportProjectRulesSaga({ payload }: any): any {
  const { projectId } = payload;
  const combinedSegments: any = [];
  const project = yield select(projectSelectors.projectById(projectId));
  const workspaces: any = yield select(projectSelectors.allWorkspaceIdName(project));
  const flatWorkspaces: any = (workspaces && workspaces.length > 0 && _.flattenDeep(workspaces)) || [];
  for (let i = 0; !_.isNil(flatWorkspaces[i]) && flatWorkspaces[i].id && i < flatWorkspaces.length; i++) {
    const segments: any = yield call(workspaceService.getAllSegmentsByWorkspaceId, flatWorkspaces[i].id);

    combinedSegments.push(Object.values(segments));
  }
  const allSegments: Segment[] = _.flattenDeep(combinedSegments);
  const rules = util.prepareForExport(_.uniqBy(allSegments, 'id'));
  download(rules, project.name);
}

function download(text: string, projectName: string) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', projectName + '-Rules.txt');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
export const projectSaga = [
  takeLatest(CREATE_PROJECT_REQUEST, createProjectRequestSaga),
  takeLatest(LOAD_PROJECT_REQUEST, loadProjectRequest),
  debounce(1200, UPDATE_PROJECT_NAME, updateProjectNameSaga),
  debounce(1200, UPDATE_DOCUMENT_NAME, updateDocNameSaga),
  debounce(1200, UPDATE_DOCUMENT_DESC, updateDocDesSaga),
  takeEvery(UPDATE_ROOT_PROJECT_ID, updateRootProjectIdSaga),
  takeLatest(UPDATE_PROJECT_SUCCESS, updateProjectNameSuccessSaga),
  takeEvery(ADD_PROJECT_REFERENCE, addProjectRefSaga),
  takeEvery(REMOVE_PROJECT_REFERENCE, removeProjectRefSaga),
  takeEvery(REFRESH_PROJECT, refreshProjectSaga),
  takeEvery(SUBSCRIBE_PROJECT_UPDATES, subscribeProjectUpdateSaga),
  takeEvery(UNSUBSCRIBE_PROJECT_UPDATES, unsubscribeProjectUpdateSaga),
  takeLatest(LOAD_ALL_PROJECTS, loadAllProjectsSaga),
  takeEvery(CREATE_DOCUMENT, createDocumentSaga),
  takeEvery(COPY_PROJECT, copyProjectSaga),
  takeEvery(DELETE_PROJECT, deleteProjectSaga),
  takeEvery(REMOVE_PROJECT, removeProjectSaga),
  takeEvery(RESTORE_PROJECT, restoreProjectSaga),
  takeEvery(REMOVE_DOCUMENT, removeDocumentSaga),
  takeEvery(RESTORE_DOCUMENT, restoreDocumentSaga),
  takeEvery(LINK_PROJECT, linkProjectSaga),
  takeEvery(EXPORT_PROJECT, exportProjectSaga),
  takeEvery(EXPORT_PROJECTS_RULES, exportProjectRulesSaga),
  takeLatest(LOAD_ALL_SUB_PROJECTS, loadAllSubProjectsSaga),
  takeEvery(PROCESS_UPDATE_NESTED_PARENT, processUpdateNestedParentSaga),
];
