import { createSelector } from 'reselect';

import { TestState } from '.';

const testState = (state: any) => state.test as TestState;

const tests = createSelector(testState, ({ tests }) => tests);
const selectedTestId = createSelector(testState, ({ selectedTestId }) => selectedTestId);
const selectedTest = createSelector(testState, ({ tests, selectedTestId }) =>
  tests.find((test) => test.uuid === selectedTestId)
);
const wordWrap = createSelector(testState, ({ wordWrap }) => wordWrap);
const autoSuggestions = createSelector(testState, ({ autoSuggestions }) => autoSuggestions);

export const testSelectors = {
  tests,
  selectedTestId,
  selectedTest,
  wordWrap,
  autoSuggestions,
};
