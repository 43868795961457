import { put, takeEvery } from 'redux-saga/effects';

import { IS_LOGGED_IN, apollo } from '../../apollo/client';
import { ENABLE_AUTH, INIT_AUTH_STATE, LOGIN_SUCCESS, LOGOUT_SUCCESS, enableAuth, hideLogin } from './actions';

function* enableAuthSaga() {
  sessionStorage.setItem('enableAuth', 'true');
}

function* initAuthStateSaga() {
  const shouldShow = sessionStorage.getItem('enableAuth');
  if (shouldShow) {
    yield put(enableAuth());
  } else {
    yield put(hideLogin());
  }
}

function* loginSuccessSaga({ payload }: any) {
  apollo.writeQuery({
    query: IS_LOGGED_IN,
    data: {
      auth: {
        accessToken: payload.accessToken,
      },
    },
  });
}

function* logoutSuccessSaga() {
  apollo.writeQuery({
    query: IS_LOGGED_IN,
    data: {
      auth: {
        accessToken: '',
      },
    },
  });
}

export const authSaga = [
  takeEvery(ENABLE_AUTH, enableAuthSaga),
  takeEvery(INIT_AUTH_STATE, initAuthStateSaga),
  takeEvery(LOGIN_SUCCESS, loginSuccessSaga),
  takeEvery(LOGOUT_SUCCESS, logoutSuccessSaga),
];
