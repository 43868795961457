import { CLEAR_ACTIVE_SEGMENT } from '../segment';
import {
  ACTIVE_RULE_VISIBILITY,
  GRID_ITEMS_UPDATED,
  RESTART_SCROLL_OBSERVER,
  RULES_PANEL_DEFAULT_HEIGHT,
  RULES_PANEL_HEIGHT,
  RULES_PANEL_WIDTH,
  SEGMENT_IN_VIEW,
  SEGMENT_OUT_OF_VIEW,
  SEGMENT_PANEL_WIDTH,
  SEGMENT_PANE_SCROLLED,
  SEGMENT_RULE_PANE_SCROLLED,
  START_SCROLL_OBSERVER,
  STOP_SCROLL_OBSERVER,
  UPDATE_RULE_BOX_HEIGHT,
  UPDATE_SNAP_GUTTER,
} from './actions';

export interface GridState {
  visibleSegmentIds: string[];
  snapGutterHeight: number;
  isActiveRuleVisible: boolean;
  rulesPanelWidth: number;
  rulesPanelHeight: number;
  rulesPanelDefaultHeight: number;
  segmentPanelWidth: number;
  snappedSegmentId: string;
  split1ScrollPosition: number;
  gridMap: {
    [segmentId: string]: {
      top: number;
      height: number;
      firstLineNumber: number;
    };
  };
}

const initialState: GridState = {
  visibleSegmentIds: [],
  snapGutterHeight: 0,
  isActiveRuleVisible: false,
  gridMap: {},
  rulesPanelWidth: 0,
  rulesPanelHeight: 0,
  rulesPanelDefaultHeight: 0,
  segmentPanelWidth: 0,
  snappedSegmentId: '',
  split1ScrollPosition: 0,
};

export interface Action {
  type: string;
  payload?: any;
}

export const gridReducer = (state: GridState = initialState, action: Action): GridState => {
  const { type, payload } = action;

  switch (type) {
    case GRID_ITEMS_UPDATED: {
      return {
        ...state,
        gridMap: {
          ...payload.gridMap,
        },
      };
    }

    case UPDATE_RULE_BOX_HEIGHT: {
      return {
        ...state,
        gridMap: {
          ...state.gridMap,
          [payload.segmentId]: {
            ...state.gridMap[payload.segmentId],
            height: payload.newHeight,
          },
        },
      };
    }

    case UPDATE_SNAP_GUTTER: {
      const { snapGutterHeight } = payload;
      return {
        ...state,
        snapGutterHeight,
      };
    }

    case SEGMENT_IN_VIEW: {
      const { segmentId } = payload;
      const newView = [...state.visibleSegmentIds];
      if (!state.visibleSegmentIds.includes(segmentId)) {
        newView.push(segmentId);
      }

      return {
        ...state,
        visibleSegmentIds: newView,
      };
    }

    case SEGMENT_OUT_OF_VIEW: {
      const { segmentId } = payload;
      return {
        ...state,
        visibleSegmentIds: state.visibleSegmentIds.filter((id) => segmentId !== id),
      };
    }

    case START_SCROLL_OBSERVER:
    case STOP_SCROLL_OBSERVER:
    case RESTART_SCROLL_OBSERVER: {
      return {
        ...state,
        visibleSegmentIds: [],
      };
    }

    case ACTIVE_RULE_VISIBILITY:
      const { isActiveRuleVisible } = payload;
      return {
        ...state,
        isActiveRuleVisible,
      };

    case CLEAR_ACTIVE_SEGMENT:
      return {
        ...state,
        isActiveRuleVisible: false,
      };

    case RULES_PANEL_WIDTH: {
      const { rulesPanelWidth } = payload;
      return {
        ...state,
        rulesPanelWidth,
      };
    }

    case RULES_PANEL_HEIGHT: {
      const { rulesPanelHeight } = payload;
      return {
        ...state,
        rulesPanelHeight,
      };
    }

    case RULES_PANEL_DEFAULT_HEIGHT: {
      const { rulesPanelDefaultHeight } = payload;
      return {
        ...state,
        rulesPanelDefaultHeight,
      };
    }

    case SEGMENT_PANEL_WIDTH:
      const { segmentPanelWidth } = payload;
      return {
        ...state,
        segmentPanelWidth,
      };

    case SEGMENT_PANE_SCROLLED:
    case SEGMENT_RULE_PANE_SCROLLED: {
      const { split1ScrollPosition } = payload;
      return {
        ...state,
        split1ScrollPosition,
      };
    }

    default:
      return state;
  }
};
