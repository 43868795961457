import * as _ from 'lodash';

import { Segment } from '../segment';
import { ACTIVATE_TEST_CASE, SORT_TEST_CASES, START_WATCH_MODE, STOP_WATCH_MODE } from '../testCase';
import { SortByType } from '../testCase';
import { LOAD_WORKSPACE_REQUEST } from '../workspace';
import {
  ADD_PROJECT_INITIATOR,
  AUTO_SUGGEST_TOGGLE,
  CLOSE_PROJECT_DRAWER,
  CLOSE_RESULTS_PANEL,
  CLOSE_RULES_PANEL,
  CLOSE_SEGMENT_PANEL,
  CLOSE_TEST_CASE_DRAWER,
  CLOSE_TEST_CASE_PANEL,
  COLLAPSE_SEGMENT,
  DASHBOARD_SETTING_LOADED,
  DASHBOARD_URL_SAVE_MSG,
  DELETE_TEST_CASE_SETTING,
  HIGHLIGHT_PROJECT_ID,
  IMPORT_PROJECT_TARGET,
  MASTER_PROJECT_ID,
  OPEN_PROJECT_DRAWER,
  OPEN_RESULTS_PANEL,
  OPEN_RULES_PANEL,
  OPEN_SEGMENT_PANEL,
  OPEN_TEST_CASE_DRAWER,
  OPEN_TEST_CASE_PANEL,
  SET_TEST_CASE_SETTING,
  SYNTAX_HIGHLIGHT_TOGGLE,
  TEST_CASE_AUTO_SUGGEST_TOGGLE,
  TEST_CASE_WORD_WRAP_TOGGLE,
  TOGGLE_AUTO_SCROLL,
  TOGGLE_DESCRIPTION,
  TOGGLE_FACTS,
  TOGGLE_REQUIRED_RESULTS,
  TOGGLE_UNWANTED_RESULTS,
  UNCOLLAPSE_SEGMENT,
  UPDATE_SPLIT1_SIZE,
  UPDATE_SPLIT2_SIZE,
  UPDATE_SPLIT3_SIZE,
  WORD_WRAP_TOGGLE,
  WORKSPACE_SETTING_LOADED,
} from './actions';

export interface TestCaseSetting {
  descriptionCollapsed: boolean;
  factsCollapsed: boolean;
  requiredResultsCollapsed: boolean;
  unwantedResultsCollapsed: boolean;
}

export const initTestCaseSetting: TestCaseSetting = {
  descriptionCollapsed: true,
  factsCollapsed: false,
  requiredResultsCollapsed: false,
  unwantedResultsCollapsed: true,
};

export interface SettingState {
  syntaxHighlight: boolean;
  wordWrap: boolean;
  testCaseWordWrap: boolean;
  autoSuggest: boolean;
  testCaseAutoSuggest: boolean;
  autoScroll: boolean;
  segmentPanelClosed: boolean;
  rulesPanelClosed: boolean;
  testCasePanelClosed: boolean;
  resultsPanelClosed: boolean;
  split1Size: number | string;
  split2Size: number | string;
  split3Size: number | string;
  lastAccessedTestCaseId: string;
  testCaseSetting: {
    [id: string]: TestCaseSetting;
  };
  testcaseDrawerClosed: boolean;
  loading: boolean;
  sortByType: SortByType;
  watchModeOn: boolean;
  projectDrawerClosed: boolean;
  addProjectInitiator: string;
  importProjectTarget: string;
  projectIdToHighlight: string;
  masterProjectId: string;

  dashboardSetting: {
    saveUrlMessageDisplayed: boolean;
  };
  collapsedSegments: Segment[];
}

const initialState: SettingState = {
  syntaxHighlight: true,
  wordWrap: true,
  testCaseWordWrap: true,
  autoSuggest: true,
  testCaseAutoSuggest: true,
  autoScroll: true,
  segmentPanelClosed: false,
  rulesPanelClosed: false,
  testCasePanelClosed: true,
  resultsPanelClosed: true,
  split1Size: '50%',
  split2Size: '50%',
  split3Size: '50%',
  testCaseSetting: {},
  lastAccessedTestCaseId: '',
  testcaseDrawerClosed: true,
  loading: false,
  sortByType: SortByType.STATUS,
  collapsedSegments: [],
  watchModeOn: true,
  projectDrawerClosed: true,
  addProjectInitiator: '',
  importProjectTarget: '',
  projectIdToHighlight: '',
  masterProjectId: '',
  dashboardSetting: {
    saveUrlMessageDisplayed: true,
  },
};

export interface Action {
  type: string;
  payload?: any;
}

export const settingReducer = (state: SettingState = initialState, action: Action): SettingState => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_WORKSPACE_REQUEST: {
      return {
        ...initialState,
        loading: true,
      };
    }

    case WORKSPACE_SETTING_LOADED: {
      const { setting } = payload;

      return {
        ...initialState,
        ...setting,
        loading: false,
      };
    }

    case CLOSE_SEGMENT_PANEL: {
      return {
        ...state,
        segmentPanelClosed: true,
      };
    }

    case OPEN_SEGMENT_PANEL: {
      return {
        ...state,
        segmentPanelClosed: false,
      };
    }

    case CLOSE_RULES_PANEL: {
      return {
        ...state,
        rulesPanelClosed: true,
      };
    }

    case OPEN_RULES_PANEL: {
      return {
        ...state,
        rulesPanelClosed: false,
      };
    }

    case CLOSE_TEST_CASE_PANEL: {
      return {
        ...state,
        testCasePanelClosed: true,
      };
    }

    case OPEN_TEST_CASE_PANEL: {
      return {
        ...state,
        testCasePanelClosed: false,
      };
    }

    case SYNTAX_HIGHLIGHT_TOGGLE: {
      return {
        ...state,
        syntaxHighlight: !state.syntaxHighlight,
      };
    }

    case WORD_WRAP_TOGGLE: {
      return {
        ...state,
        wordWrap: !state.wordWrap,
      };
    }

    case TEST_CASE_WORD_WRAP_TOGGLE: {
      return {
        ...state,
        testCaseWordWrap: !state.testCaseWordWrap,
      };
    }

    case AUTO_SUGGEST_TOGGLE: {
      return {
        ...state,
        autoSuggest: !state.autoSuggest,
      };
    }

    case TEST_CASE_AUTO_SUGGEST_TOGGLE: {
      return {
        ...state,
        testCaseAutoSuggest: !state.testCaseAutoSuggest,
      };
    }

    case TOGGLE_AUTO_SCROLL: {
      return {
        ...state,
        autoScroll: !state.autoScroll,
      };
    }

    case UPDATE_SPLIT1_SIZE: {
      return {
        ...state,
        split1Size: payload.size,
      };
    }

    case UPDATE_SPLIT2_SIZE: {
      return {
        ...state,
        split2Size: payload.size,
      };
    }

    case UPDATE_SPLIT3_SIZE: {
      return {
        ...state,
        split3Size: payload.size,
      };
    }

    case CLOSE_RESULTS_PANEL: {
      return {
        ...state,
        resultsPanelClosed: true,
      };
    }

    case OPEN_RESULTS_PANEL: {
      return {
        ...state,
        resultsPanelClosed: false,
      };
    }

    case TOGGLE_FACTS:
    case TOGGLE_REQUIRED_RESULTS:
    case TOGGLE_UNWANTED_RESULTS:
    case TOGGLE_DESCRIPTION: {
      const { testCaseId } = payload;

      return {
        ...state,
        testCaseSetting: {
          ...state.testCaseSetting,
          [testCaseId]: {
            ...initTestCaseSetting,
            ...state.testCaseSetting[testCaseId],
            ...payload,
          },
        },
      };
    }

    case OPEN_TEST_CASE_DRAWER: {
      return {
        ...state,
        testcaseDrawerClosed: false,
      };
    }

    case CLOSE_TEST_CASE_DRAWER: {
      return {
        ...state,
        testcaseDrawerClosed: true,
      };
    }

    case DELETE_TEST_CASE_SETTING: {
      const { id } = payload;
      delete payload['testCaseId']; // dont want to copy testCaseId. just setting required.

      const updatedTestCaseSettings = _.omit(state.testCaseSetting, id);
      return {
        ...state,
        testCaseSetting: {
          ...updatedTestCaseSettings,
        },
      };
    }

    case ACTIVATE_TEST_CASE: {
      const { id } = payload;
      return {
        ...state,
        lastAccessedTestCaseId: id,
      };
    }

    case SET_TEST_CASE_SETTING: {
      const { id, setting } = payload;

      return {
        ...state,
        testCaseSetting: {
          ...state.testCaseSetting,
          [id]: {
            ...setting,
          },
        },
      };
    }

    case SORT_TEST_CASES: {
      const { sortByType } = payload;
      return {
        ...state,
        sortByType,
      };
    }

    case START_WATCH_MODE: {
      return {
        ...state,
        watchModeOn: true,
      };
    }

    case STOP_WATCH_MODE: {
      return {
        ...state,
        watchModeOn: false,
      };
    }

    case OPEN_PROJECT_DRAWER: {
      return {
        ...state,
        projectDrawerClosed: false,
      };
    }

    case CLOSE_PROJECT_DRAWER: {
      return {
        ...state,
        projectDrawerClosed: true,
      };
    }
    case ADD_PROJECT_INITIATOR: {
      const { projectId } = payload;
      return {
        ...state,
        addProjectInitiator: projectId,
      };
    }
    case IMPORT_PROJECT_TARGET: {
      const { projectIdToImport } = payload;
      return {
        ...state,
        importProjectTarget: projectIdToImport,
      };
    }

    case HIGHLIGHT_PROJECT_ID: {
      const { projectId } = payload;
      return {
        ...state,
        projectIdToHighlight: projectId,
      };
    }

    case MASTER_PROJECT_ID: {
      const { projectId } = payload;
      return {
        ...state,
        masterProjectId: projectId,
      };
    }

    case DASHBOARD_URL_SAVE_MSG: {
      return {
        ...state,
        dashboardSetting: {
          saveUrlMessageDisplayed: true,
        },
      };
    }

    case DASHBOARD_SETTING_LOADED: {
      const { dashboardSetting } = payload;
      return {
        ...state,
        dashboardSetting: {
          ...dashboardSetting,
        },
      };
    }

    case COLLAPSE_SEGMENT: {
      return {
        ...state,
        collapsedSegments: [...state.collapsedSegments, payload],
      };
    }

    case UNCOLLAPSE_SEGMENT: {
      const { id } = payload;
      return {
        ...state,
        collapsedSegments: state.collapsedSegments.filter((seg) => seg.id !== id),
      };
    }

    default:
      return state;
  }
};
