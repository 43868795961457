import { MsalProvider } from '@azure/msal-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';

import { runSagaMiddleware, store } from './src/store/createStore';
import { GlobalStyle } from './src/styles/global.css';
import { getMsalInstance } from './src/utils/get-msal-instance';
import { queryClient } from './src/utils/query-client';

export const wrapWithProvider = ({ element }: { element: any }) => {
  runSagaMiddleware();
  const msalInstance = getMsalInstance();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <GlobalStyle />
          <SnackbarProvider maxSnack={3}>{element}</SnackbarProvider>
        </MsalProvider>
      </Provider>
    </QueryClientProvider>
  );
};
